import './artwork-item.css';

import { ArtworkObject } from '.';
import { Link } from 'react-router-dom';
import React from 'react';
import { currentPage } from '..';
import { media_url } from '../../../service';

export default class ArtworkListItem extends React.Component<{
  artwork: ArtworkObject;
  selected: boolean;
}, {
  artwork_title: string;
  default_image_url: string | null;
  default_image_loaded: boolean
}> {

  private didMount: boolean = false;
  private defaultImageUrl: string = require('../../../assets/images/artwork-icon.png');
  private prev_refresh_id: number = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      artwork_title: '',
      default_image_url: null,
      default_image_loaded: false,
    }
    this.updateArtwork = this.updateArtwork.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    this.updateArtwork();
  }

  componentDidUpdate(prevProps: any, _prevState: any) {
    if (this.props.selected) {
      // console.log('artwork-item: on-previous: refreshId: ', this.prev_refresh_id);
      // console.log('artwork-item: on-updated : refreshId: ', prevProps.artwork.refreshId);
      // console.log('-------------------------------------');
    }
    if (prevProps.artwork.refreshId !== this.prev_refresh_id) {
      this.prev_refresh_id = prevProps.artwork.refreshId;
      this.updateArtwork();
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  updateArtwork() {
    if (!this.didMount) return;
    if (this.props.selected) {
      // console.log('artwork-item: updateArtwork: ', this.props.artwork.eventArtwork);
    }
    this.setState({ artwork_title: this.props.artwork.eventArtwork.event_artwork_title, default_image_loaded: false });
  }

  render() {
    return (
      <Link
        to={currentPage.ARTWORKS + "/" + this.props.artwork.eventArtwork.event_id}
        className={this.props.selected ? "artwork-item-cont item-selected" : "artwork-item-cont"}>
        <div className="artwork-item-image-cont">
          <div className="artwork-item-image-top" />
          <img
            className="artwork-item-image"
            src={this.state.default_image_url ? this.state.default_image_url :
              (this.props.artwork.eventArtwork.event_image_title && this.props.artwork.eventArtwork.event_image_title ?
                media_url + this.props.artwork.eventArtwork.event_image_title : this.defaultImageUrl)}
            onError={(e) => {
              if (this.state.default_image_loaded) return;
              this.setState({ default_image_url: this.defaultImageUrl, default_image_loaded: true });
            }}
            alt="event profile image"
            crossOrigin="anonymous"
            referrerPolicy='origin-when-cross-origin'
          />
        </div>
        <div className="artwork-item-info">
          <div className="artwork-item-info-title">{this.props.artwork.eventArtwork.event_name}</div>
          <div className="artwork-item-bottom-cont">
            <div className="artwork-item-count">
              <span className='artwork-item-cont-title'>{this.state.artwork_title.length > 0 ? 'Added Artwrok' : 'No Artwork'}</span>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}