import 'rsuite/dist/rsuite.min.css';
import './event-edit.css';

import DatabaseManager, { media_url } from '../../../service';

import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { DateRangePicker } from 'rsuite';
import Map from '../../map';
import Period from '../../period';
import React from 'react';
import { UserEvent } from '../../models';
import addDays from 'date-fns/addDays';

// const { before } = DateRangePicker;

export default class EditEvent extends React.Component<{
    user_id: string | null;
    event_id: string;
    onUpdateComplete(event: UserEvent): void;
    onCancelUpdate(): void;
}, {
    event_name: string,
    event_desc: string,
    event_about: string,
    event_venue: string,
    event_location: { lat: number, lng: number },
    isLocationModalOpen: boolean,
    isCalenderModalOpen: boolean,
    event_date: DateRange,
    show_date: boolean,
    image_title: string,
    previous_image_title: string,
    event_image_src: string | null,
    event_image_file: File | null,
    error: string,
    canCreate: boolean,
    isLoading: boolean,
    // data_loaded: boolean,
    selected_event_id: string | null,
    is_updating: boolean,
}> {
    private didMount: boolean = false;
    private databaseManager: DatabaseManager = new DatabaseManager();
    private request_update_component: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            event_name: "",
            event_desc: "",
            event_about: "",
            event_venue: "",
            event_location: { lat: 0, lng: 0 },
            isLocationModalOpen: false,
            isCalenderModalOpen: false,
            event_date: [Period.getTodayStartTime(), Period.getTodayEndTime()],
            show_date: false,
            image_title: "",
            previous_image_title: "",
            event_image_src: null,
            event_image_file: null,
            // error: "Something went wrong!",
            error: "",
            canCreate: false,
            isLoading: true,
            selected_event_id: null,
            is_updating: false,
        }
    }

    componentDidMount() {
        this.didMount = true;
        this.loadData();
    }

    componentWillUnmount() {
        this.didMount = false;
    }

    loadData() {
        if (!this.didMount) return;
        if (!this.props.user_id || !this.props.event_id) return;
        this.databaseManager.getSingleEvent(this.props.user_id, this.props.event_id, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                if (result) {
                    if (result.success && result.success.data && result.success.data.length > 0) {
                        // // console.log("data: ", result.success.data);
                        const data = result.success.data[0];
                        const userEvent: UserEvent = {
                            id: data.id ? data.id : "",
                            name: data.name ? data.name : "",
                            description: data.description ? data.description : "",
                            about: data.about ? data.about : "",
                            venue: data.venue ? data.venue : "",
                            image_title: data.image_title ? data.image_title : null,
                            artwork_title: data.artwork_title ? data.artwork_title : null,
                            location_latlng: data.location_latlng ? data.location_latlng : null,
                            applicants_count: data.applicants_count ? data.applicants_count : 0,
                            attended_count: data.applicants_count ? data.applicants_count : 0,
                            created_date: data.created_date ? data.created_date : 0,
                            start_date: data.start_date ? data.start_date : 0,
                            end_date: data.end_date ? data.end_date : 0,
                        }
                        const start_date = new Date(userEvent.start_date);
                        const end_date = new Date(userEvent.end_date);
                        const event_date: DateRange = [start_date, end_date];
                        const location_latlng = userEvent.location_latlng ? JSON.parse(userEvent.location_latlng) : { lat: 0, lng: 0 };
                        this.setState({
                            event_name: userEvent.name,
                            event_desc: userEvent.description ? userEvent.description : "",
                            event_about: userEvent.about ? userEvent.about : "",
                            event_venue: userEvent.venue,
                            event_date: event_date,
                            show_date: true,
                            event_location: { lat: location_latlng.lat ? location_latlng.lat : 0, lng: location_latlng.lng ? location_latlng.lng : 0 },
                            image_title: userEvent.image_title ? userEvent.image_title : "",
                            event_image_src: userEvent.image_title ? media_url + userEvent.image_title : null,
                            event_image_file: null,
                        });
                        if (this.request_update_component) {
                            this.request_update_component = false;
                            this.props.onUpdateComplete(userEvent);
                        }
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
            },
            onError: (_error: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
            }
        });
    }

    onValueChange = (field_type: 'name' | 'venue' | 'desc' | 'about', event: any) => {
        switch (field_type) {
            case 'name':
                this.setState({ event_name: event.target.value });
                break;
            case 'desc':
                this.setState({ event_desc: event.target.value });
                break;
            case 'about':
                this.setState({ event_about: event.target.value });
                break;
            case 'venue':
                this.setState({ event_venue: event.target.value });
                break;
        }
    };

    onSubmit = (e: any) => {
        e.preventDefault();
        if (!this.props.user_id) return;
        if (!this.props.event_id) return;
        this.setState({ error: '' });

        const name: string = this.state.event_name;
        const venue: string = this.state.event_venue;
        const description: string = this.state.event_desc;
        const about: string = this.state.event_about;
        const image_file: File | null = this.state.event_image_file;
        const previous_image_title: string = this.state.previous_image_title;
        const location_latlng = this.state.event_location;
        const start_date: number = (this.state.event_date[0]).valueOf();
        const end_date: number = (this.state.event_date[1]).valueOf();

        if (name.length === 0) {
            this.setState({ error: 'Please enter event name.' });
            return;
        }
        if (venue.length === 0) {
            this.setState({ error: 'Please enter event venue or location name.' });
            return;
        }
        if (description.length === 0) {
            this.setState({ error: 'Please enter event description.' });
            return;
        }

        this.setState({ is_updating: true });

        this.databaseManager.updateEvent(this.props.user_id, this.props.event_id,
            image_file, previous_image_title,
            name, venue, description, about,
            start_date, end_date, location_latlng, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ is_updating: false });
                if (result.success) {
                    // if (result.success.data && result.success.data.length > 0) {
                    //   const data = result.success.data[0];
                    //   // console.log("updateEvent: onResult: data: ", data);
                    // }
                    this.setState({
                        event_name: '',
                        event_desc: '',
                        event_about: '',
                        event_venue: '',
                        event_image_src: null,
                        event_image_file: null,
                        event_location: { lat: 0, lng: 0 },
                        event_date: [Period.getTodayStartTime(), Period.getTodayEndTime()],
                        show_date: false,
                        isLoading: false,
                    });
                    this.request_update_component = true; // finish this class
                    this.loadData();
                } else {
                    this.setState({ error: 'Something went wrong while updating event.\nPlease try again.' });
                    // console.log("updateEvent: onResult: error: ", result.error);
                }
            },
            onError: (_error: any) => {
                if (!this.didMount) return;
                this.setState({ is_updating: false, error: 'Something went wrong while updating event.\nPlease try again.' });
            }
        });
    }

    private datePicker(previous_date: DateRange) {
        // const lower_date = new Date();
        // lower_date.setFullYear(2022, 1, 1);
        // lower_date.setHours(0, 0, 0, 0);
        return (
            <DateRangePicker
                format={"dd/MM/yyyy HH:mm:ss"}
                appearance="subtle"
                placeholder="Select event period"
                character="  ~  "
                // size="sm"
                defaultValue={previous_date}
                cleanable={false}
                // disabledDate={
                //     // combine && combine(before && before(lower_date), afterToday && afterToday())
                //     // combine && combine(before && before(lower_date))
                //     before && before(lower_date)
                // }
                style={{
                    backgroundColor: 'var(--color-background)',
                    borderRadius: '8px',
                }}
                ranges={[
                    {
                        label: 'Today',
                        value: [Period.getTodayStartTime(), Period.getTodayEndTime()]
                    },
                    {
                        label: 'Tomorrow',
                        value: [addDays(new Date(), 1), addDays(new Date(), 1)]
                    }
                ]}
                onOpen={() => {
                    this.setState({ isCalenderModalOpen: true });
                }}
                onChange={(date: DateRange | null, event: React.SyntheticEvent<Element, Event>) => {
                    // console.log('date-range-picker: changed: ', date);
                }}
                onOk={(date: DateRange, event: React.SyntheticEvent) => {
                    event.preventDefault();
                    // console.log('date-range-picker: ok: ', date);
                    if (date)
                        this.setState({ event_date: date });
                }}
                onClose={() => {
                    this.setState({ isCalenderModalOpen: false });
                }}
            />
        );
    }

    render() {
        return (
            <div className="event-edit">
                <div className="event-edit-modal"/>
                <div className="event-edit-main-lay">
                    <button className="event-edit-cancel-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            if (!this.didMount) return;
                            this.props.onCancelUpdate(); // finish this class
                        }}>Cancel</button>
                    <span className="event-edit-title">Edit Event</span>
                    {(this.state.is_updating || this.state.error) &&
                        <div className="event-form-output">
                            {this.state.is_updating && <i className="fas fa-spinner fa-spin loading-icon"></i>}
                            {this.state.error && <span
                                className="error-text selector-none"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!this.didMount) return;
                                    this.setState({ error: '', is_updating: false });
                                }}>
                                {this.state.error}</span>}
                        </div>
                    }
                    <form className='event-form-cont' onSubmit={this.onSubmit}>
                        <input className='name-input input'
                            name='event_name'
                            value={this.state.event_name}
                            onChange={(e) => this.onValueChange('name', e)}
                            type='text'
                            placeholder='Enter Event Name' />
                        <input
                            // rows={5}
                            // cols={50}
                            maxLength={100}
                            className='desc-input input'
                            name='event_desc'
                            type='text'
                            value={this.state.event_desc}
                            onChange={(e) => this.onValueChange('desc', e)}
                            placeholder='Short Description' />
                        <input
                            // rows={200}
                            // cols={50}
                            maxLength={1000}
                            className='about-input input'
                            name='event_about'
                            type='text'
                            value={this.state.event_about}
                            onChange={(e) => this.onValueChange('about', e)}
                            placeholder='About (optional)' />
                        <input
                            className='venue-input input'
                            name='event_venue'
                            value={this.state.event_venue}
                            onChange={(e) => this.onValueChange('venue', e)}
                            type='text'
                            placeholder='Venue Or Location Name' />
                        <button
                            className='event-location-btn'
                            onClick={(e) => {
                                e.preventDefault();
                                if (!this.didMount) return;
                                this.setState({ isLocationModalOpen: true });
                                // const root_modal = document.getElementById("root-modal");
                                // if (root_modal) {
                                //   root_modal.style.display = "flex";
                                // }
                            }}>
                            Select Event Location (optional)
                        </button>
                        <div className="event-location-map-modal"
                            style={{
                                display: this.state.isLocationModalOpen || this.state.isCalenderModalOpen ? 'flex' : 'none'
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!this.didMount) return;
                                this.setState({ isLocationModalOpen: false, isCalenderModalOpen: false });
                            }} />
                        <div className='event-location-map-cont'
                            style={{
                                display: this.state.isLocationModalOpen ? 'flex' : 'none'
                            }}>
                            <Map
                                is_dashboard={false}
                            // locations={[]}
                            // is_location_data_loaded={!this.state.dashboard_loading}
                            // location={undefined}
                            // last_location_updated={0}
                            />
                        </div>
                        <span className="event-date-title">Select Event Date</span>
                        {this.state.show_date && <div className="event-date-cont">{this.datePicker(this.state.event_date)}</div>}
                        <button className='event-image-btn'
                            onClick={(e) => {
                                e.preventDefault();
                                if (!this.didMount) return;
                                const imageInputFile = document.getElementById("event-edit-image-input-file");
                                if (imageInputFile) {
                                    imageInputFile.click();
                                }
                            }}>
                            Select Event's Profile Image (optional)
                        </button>
                        <input className="event-image-input" hidden={true} multiple={false}
                            type="file"
                            id="event-edit-image-input-file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                                e.preventDefault();
                                if (!this.didMount) return;
                                const files: FileList | null = e.target.files;
                                if (files && files.length > 0) {
                                    const file: File = files[0];
                                    if (file) {
                                        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
                                        const actual_filesize = parseFloat(filesize);
                                        if (actual_filesize <= 2) {
                                            const filePath = URL.createObjectURL(file);
                                            this.setState({
                                                event_image_src: filePath,
                                                event_image_file: file, /* this will be updated on server storage*/
                                                previous_image_title: this.state.image_title /* set previous_image_title to remove on server storage */
                                            });
                                        } else {
                                            this.setState({ error: 'Image size should be less than 2 MB' });
                                        }
                                    }
                                }
                            }}
                        />
                        {this.state.event_image_src &&
                            <div className="event-image-cont">
                                <i className="fas fa-times-circle event-image-icon" title={"remove image"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!this.didMount) return;
                                        this.setState({
                                            event_image_src: null,
                                            event_image_file: null,
                                            previous_image_title: this.state.image_title /* set previous_image_title to remove on server storage */
                                        });
                                    }} />
                                <div className="event-image-top" />
                                <img className="event-image" src={this.state.event_image_src} alt={"event profile image"} />
                            </div>
                        }
                        <button className='update-event-btn' type='submit'>Update</button>
                    </form>
                </div>
            </div>
        )
    }
}