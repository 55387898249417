import './index.css';

import * as H from 'history';

import {
  Link,
  match,
  withRouter,
} from 'react-router-dom';

import AuthContext from '../../service/context';
import DatabaseManager from '../../service';
import React from 'react';

// import Cookies from 'universal-cookie';







class SignUp extends React.Component<{
  history: H.History,
  location: H.Location,
  match: match,
}, {
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  password_confirm: string,
  error: any | null,
  isLoading: boolean,
}> {

  static contextType = AuthContext;
  private databaseManager: DatabaseManager;
  // private cookies: Cookies = new Cookies();
  private didMount: boolean = false;

  constructor(props: any) {
    super(props);

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirm: '',
      error: '',
      isLoading: false,
    };

    this.databaseManager = new DatabaseManager();
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    // const username = this.cookies.get('user_id');
    const user_id = localStorage.getItem('user_id');
    if (user_id) {
      this.context.setIsAuthenticated(true);
      let { from }: any = this.props.location.state || { from: { pathname: '/dashboard' } };
      this.props.history.replace(from);
    }
    document.title = "Signup - Laibon Registration";
    var theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
    const theme_btn: any = document.getElementById("theme-btn");

    if (theme_btn) {
      theme_btn.checked = theme && theme === 'dark' ? true : false;
      theme_btn.addEventListener('change', (e: EventTarget | any) => {
        if (e && e.target) {
          theme = e.target.checked ? 'dark' : 'light';
          localStorage.setItem('theme', theme);
          document.documentElement.className = theme;
        }
      });
    }

    if (theme) document.documentElement.className = theme;
  }

  componentWillUnmount() {
    this.didMount = false;
  }



  onChange = (event: any, type: 'first_name' | 'last_name' | 'email' | 'password' | 'password_confirm') => {
    // this.setState({
    //   [type]: event.target.value,
    //   error: null,
    // });
    switch (type) {
      case 'first_name':
        this.setState({
          first_name: event.target.value,
          error: null,
        });
        break;
      case 'last_name':
        this.setState({
          last_name: event.target.value,
          error: null,
        });
        break;
      case 'email':
        this.setState({
          email: event.target.value,
          error: null,
        });
        break;
      case 'password':
        this.setState({
          password: event.target.value,
          error: null,
        });
        break;
      case 'password_confirm':
        this.setState({
          password_confirm: event.target.value,
          error: null,
        });
        break;
      default:
        break;
    }

    // this.setState({
    //   isValid: this.state.first_name.length > 0 &&
    //     this.state.last_name.length > 0 &&
    //     this.state.email.length > 5 &&
    //     this.state.password.length > 3 &&
    //     this.state.password !== this.state.password_confirm
    // });
  };

  onSubmit = (event: any) => {
    event.preventDefault();
    const { first_name, last_name, email, password, password_confirm } = this.state;

    // check if all fields are filled
    if (first_name.length === 0 || last_name.length === 0 || email.length === 0 || password.length === 0 || password_confirm.length === 0) {
      this.setState({ error: null });
      // wait for 100ms before showing error
      setTimeout(() => {
        this.setState({ error: { message: 'Please fill all fields' } });
      }, 100);
      return;
    }

    // check if email is valid and also email is matched with standard email pattern
    if (email.length < 5 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      this.setState({ error: null });
      // wait for 100ms before showing error
      setTimeout(() => {
        this.setState({ error: { message: 'Email is invalid' } });
      }, 100);
      return;
    }
    // show error if password and password_confirm do not match
    if (password !== password_confirm) {
      this.setState({ isLoading: false, error: { message: 'Password does not match' } });
      return;
    }

    this.setState({
      isLoading: true,
      error: null,
    });

    let { from }: any = this.props.location.state || { from: { pathname: '/dashboard' } };

    this.databaseManager.registerUser(first_name, last_name, email, password, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false });
        // // console.log(result);
        if (result) {
          if (result.success && result.success.data && result.success.data.length > 0) {
            const user_id = result.success.data[0].user_id;
            localStorage.setItem('user_id', user_id);
            first_name && localStorage.setItem('first_name', first_name);
            last_name && localStorage.setItem('last_name', last_name);
            this.context.setIsAuthenticated(true);
            this.props.history.replace(from);
          } else {
            this.setState({
              isLoading: false,
              error: { message: result.error && result.error.message ? result.error.message : 'Something went wrong'},
            });
          }
        }
        else {
          this.setState({ error: { message: 'Could not signup. Reason, Signing Up Error!' } });
        }
      },
      onError: (_error: any) => {
        this.setState({ isLoading: false, error: { message: 'Could not signup. Reason, Signing Up Error!' } });
      }
    });
  }

  render() {
    return (
      <div className="signup-main">
        <div className="main-header main-header-full border-bottom" style={{ width: '100% !important' }}>
          <span className="app-title">Laibon Registration</span>
          <div className="pallete-container">
            <label className="switch" title="Change theme">
              <input type="checkbox" className="checkbox" id="theme-btn" />
              <span className="toggle-thumb">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
                </svg>
              </span>
            </label>
          </div>
        </div>

        <form className='form-container' onSubmit={this.onSubmit}>
          <span className="signup-title">Sign Up</span>
          <input className='first-name-input input' name='first-name' autoComplete='first-name' value={this.state.first_name} onChange={(e) => this.onChange(e, 'first_name')} type='text' placeholder='First Name' />
          <input className='last-name-input input' name='last-name' autoComplete='last-name' value={this.state.last_name} onChange={(e) => this.onChange(e, 'last_name')} type='text' placeholder='Last Name' />
          <input className='email-input input' name='email' autoComplete='current-email' value={this.state.email} onChange={(e) => this.onChange(e, 'email')} type='email' placeholder='Email Address' />
          <input className='password-input input' name='password' autoComplete='current-password' value={this.state.password} onChange={(e) => this.onChange(e, 'password')} type='password' placeholder='Password' />
          <input className='password-input input' name='password' autoComplete='off' value={this.state.password_confirm} onChange={(e) => this.onChange(e, 'password_confirm')} type='password' placeholder='Confirm Password' />
          <button className='signup-btn' type='submit'>Sign Up</button>
          <div className='create-account-lay'>
            <span className='create-account-title'>Already Have Account ?</span>
            <Link className='create-account-link' to='/login'>Log In</Link>
          </div>
          {this.state.isLoading && <i className="fa-li fa fa-spinner fa-spin loading-icon"></i>}
          {this.state.error && <p className="message-box">{this.state.error.message}</p>}
          <div className='footer-cont'>
            <div className='footer-seg'>
              <Link className='footer-link' to='/' target='_self'>Home</Link>
              <Link className='footer-link' to='/privacy' target='_self'>Privacy</Link>
              <Link className='footer-link' to='/terms' target='_self'>Terms</Link>
            </div>
            <div className='footer-seg'>
              <span className='footer-text'>Copyright &copy;</span>
              <a className='footer-link' href='https://imperialinnovations.co.tz' target='_blank'>Imperial Innovations</a>
              <span className='footer-text'>| 2022</span>
            </div>
            <div className='footer-seg'>
              <span className='footer-text'>All Rights Reserved</span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(SignUp);