import './artwork-details.css';

import DatabaseManager, { media_url } from '../../../service';

import Dropzone from 'react-dropzone';
import { EventArtwork } from '../../models';
import React from 'react';
import { currentPage } from '..';
import { v4 as uuidV4 } from 'uuid';

export default class ArtworkDetails extends React.PureComponent<{
  user_id: string | null;
  event_id: string | null;
  current_page: string;
  onArtworkUpdated(artwork: EventArtwork): void;
}, {
  event_id: string | null,
  artwork_file: File | null,
  artwork_image_src: string | null,
  previous_artwork_title: string,
  artwork_image_changed: boolean,
  is_saving: boolean,
  error: string | null,
  isLoading: boolean,
  data_loaded: boolean,
}> {

  private previous_event_id: string | null;
  private didMount: boolean = false;
  private databaseManager: DatabaseManager;
  private eventArtwork: EventArtwork | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      event_id: null,
      artwork_file: null,
      artwork_image_src: null,
      previous_artwork_title: "",
      artwork_image_changed: false,
      is_saving: false,
      error: null,
      isLoading: true,
      data_loaded: false,
    };
    this.databaseManager = new DatabaseManager();
    this.previous_event_id = this.props.event_id;
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    this.loadData();
  }

  componentDidUpdate() {
    if (!this.didMount) return;
    // // console.log("artwork details: update: props: ", this.props);
    if (!this.props.user_id || !this.props.event_id) return;
    if (this.props.event_id !== this.previous_event_id) {
      this.previous_event_id = this.props.event_id;
      this.setState({ data_loaded: false }); // reload data
      setTimeout(() => {
        if (!this.didMount) return;
        this.loadData();
      }, 100);
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    // // console.log("artwork details: loadData: state: ", this.state);
    if (!this.didMount) return;
    if (!this.props.user_id || !this.props.event_id) return;
    if (this.state.data_loaded) return;
    this.databaseManager.getSingleArtwork(this.props.user_id, this.props.event_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        try {
          this.setState({ isLoading: false, data_loaded: true });
          if (result) {
            if (result.success && result.success.data && result.success.data.length > 0) {
              // console.log("data: ", result.success.data);
              const data = result.success.data[0];
              const eventArtwork: EventArtwork = {
                event_id: data.id ? data.id : "",
                event_name: data.name ? data.name : "",
                event_image_title: data.image_title ? data.image_title : "",
                event_artwork_title: data.artwork_title ? data.artwork_title : "",
              }

              this.eventArtwork = eventArtwork;
              this.setState({
                event_id: eventArtwork.event_id,
                artwork_image_src: eventArtwork.event_artwork_title ? media_url + eventArtwork.event_artwork_title : "",
                previous_artwork_title: eventArtwork.event_artwork_title,
                artwork_file: null,
                artwork_image_changed: false,
              });
            } else {
              this.setState({ isLoading: false, data_loaded: false });
            }
          }
        } catch (e) {
          // console.log("error: ", e);
          this.setState({ isLoading: false, data_loaded: false });
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
      }
    });
  }

  onSaveBtnClick() {
    if (!this.didMount) return;
    if (!this.props.user_id || !this.props.event_id) return;
    // if (!this.state.artwork_file) return;

    const previous_artwork_title = this.state.previous_artwork_title;
    const artwork_file = this.state.artwork_file;

    this.setState({ is_saving: true });

    this.databaseManager.updateArtwork(this.props.user_id, this.props.event_id, artwork_file, previous_artwork_title, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ is_saving: false });
        if (result) {
          if (result.success && result.success.data && result.success.data.length > 0) {
            // console.log("data: ", result.success.data);
            const data = result.success.data[0];
            const eventArtwork: EventArtwork = {
              event_id: this.eventArtwork ? this.eventArtwork.event_id : "",
              event_name: this.eventArtwork ? this.eventArtwork.event_name : "",
              event_image_title: this.eventArtwork ? this.eventArtwork.event_image_title : "",
              event_artwork_title: data.new_artwork_title ? data.new_artwork_title : "",
              // event_artwork_title: data.new_artwork_title ? data.new_artwork_title : previous_artwork_title,
            }

            this.eventArtwork = eventArtwork;
            this.setState({
              event_id: eventArtwork.event_id,
              artwork_image_src: eventArtwork.event_artwork_title ? media_url + eventArtwork.event_artwork_title : "",
              previous_artwork_title: eventArtwork.event_artwork_title,
              artwork_file: null,
              error: 'Artwork successful saved.',
              data_loaded: false,
              artwork_image_changed: false,
            });
            // wait for 1 sec
            setTimeout(() => {
              this.setState({ error: '' });
              this.forceUpdate();
              this.loadData();
            }, 1000);
            this.props.onArtworkUpdated(this.eventArtwork);
          } else {
            this.setState({ error: 'Something went wrong while updating artwork.\nPlease try again.' });
            // console.log("createEvent: onResult: error: ", result.error);
          }
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ is_saving: false, error: 'Something went wrong while updating artwork.\nPlease try again.' });
      }
    });
  }

  render() {
    return (
      <>
        {(this.state.is_saving || this.state.error) &&
          <div className="artwork-output">
            {this.state.is_saving && <i className="fas fa-spinner fa-spin loading-icon"></i>}
            {this.state.error && <span
              className="error-text selector-none"
              onClick={(e) => {
                e.preventDefault();
                if (!this.didMount) return;
                this.setState({ error: '', is_saving: false });
              }}>
              {this.state.error}</span>}
          </div>}
        <div className="artwork-details"
          style={{
            display: this.props.current_page === currentPage.ARTWORKS + "/" + this.props.event_id ? 'flex' : 'none'
          }}>
          <div className="artwork-details-list">
            <Dropzone
              accept={'image/jpeg,image/png'}
              maxFiles={1}
              minSize={0}
              maxSize={2000000}
              onDrop={(acceptedFiles: File[]) => {
                if (!this.didMount) return;
                if (acceptedFiles.length > 0) {
                  const file: File = acceptedFiles[0];
                  const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
                  const actual_filesize = parseFloat(filesize);
                  if (actual_filesize <= 2) {
                    const filePath = URL.createObjectURL(file);
                    this.setState({ artwork_file: file, artwork_image_src: filePath, artwork_image_changed: true });
                  } else {
                    this.setState({ error: 'Image size should be less than 2 MB' });
                  }
                }
              }}>
              {({ getRootProps, getInputProps }) => (
                <section className="artwork-input-cont">
                  <div {...getRootProps({ className: 'artwork-input-dropzone border-dash-anim' })}>
                    <input {...getInputProps()} />
                    <p>{"Drag 'n' drop artwork (Image) file < 2 MB here, or click to select"}</p>
                  </div>
                  {this.state.artwork_file &&
                    <aside>
                      <ul>{this.state.artwork_file.name} - {this.state.artwork_file.size} Bytes</ul>
                    </aside>}
                </section>
              )}
            </Dropzone>
            {this.state.artwork_image_src &&
              <div className="artwork-image-cont">
                <i className="fas fa-times-circle artwork-image-icon"
                  title={"remove image"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!this.didMount) return;
                    this.setState({ artwork_image_src: null, artwork_file: null, artwork_image_changed: true });
                  }} />
                <div className="artwork-image-top" />
                <img
                  className="artwork-image"
                  src={this.state.artwork_image_src}
                  alt={"event artwork image"}
                />
              </div>
            }
            {this.state.artwork_image_changed && 
            <div key={uuidV4()} className="artwork-details-btns-cont">
              <button
                className="artwork-details-ok-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  this.onSaveBtnClick();
                }}>
                Save
              </button>
            </div>
            }
          </div>
        </div>
      </>
    );
  }
}  