import './index.css';

import GoogleMapReact from 'google-map-react';
import React from 'react';

// import Geocode from "react-geocode";

export const API_KEY: string = "AIzaSyA-wq7bDNsJ3NJxsyCZdyvS0gkR8pFsXDU";

export default class Map extends React.PureComponent<{
  is_dashboard: boolean;
  // locations: Array< GoogleMapReact.Coords | undefined>;
  // location: GoogleMapReact.Coords | undefined;
  // is_location_data_loaded: boolean;
  // last_location_updated: number;
},{
  location: GoogleMapReact.Coords | undefined,
  country: string,
  city: string,
  address: string,
}> {

  private location: GoogleMapReact.Coords = { lat: 0, lng: 0 };// lat: -6.786525, // lng: 39.274040

  private didMount: boolean = false;

  constructor(props: any) {
    super(props);

    // Geocode.setApiKey(API_KEY);
    // Geocode.setLanguage("en");
    // Geocode.setRegion("tz");

    this.state = {
      location: /*this.props.location ? this.props.location : */{lat: 0, lng: 0},
      country: "",
      city: "",
      address: "",
    }

    // this.location = this.props.location ? this.props.location : { lat: 0, lng: 0};
  }

  componentDidMount() {
    this.didMount = true;
    // this.getAddress();
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // if (prevProps.location !== this.props.location) {
    //   if (this.didMount) {
    //     this.location = this.props.location ? this.props.location : { lat: 0, lng: 0};
    //     this.getAddress();
    //   }
    // }
  }

  // getAddress() {
  //   if (!this.props.is_dashboard && this.location.lat !== 0 && this.location.lng !== 0) {
  //     this.setState({ location: this.props.location })
  //     Geocode.fromLatLng((this.location.lat).toString(), (this.location.lng).toString()).then(
  //       (response) => {
  //         // // console.log('geocode: result: ', response);
  //         const address = response.results[0].formatted_address;
  //         let city, state, country;
  //         for (let i = 0; i < response.results[0].address_components.length; i++) {
  //           for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
  //             switch (response.results[0].address_components[i].types[j]) {
  //               case "locality":
  //                 city = response.results[0].address_components[i].long_name;
  //                 break;
  //               case "administrative_area_level_1":
  //                 state = response.results[0].address_components[i].long_name;
  //                 break;
  //               case "country":
  //                 country = response.results[0].address_components[i].long_name;
  //                 break;
  //             }
  //           }
  //         }
  //         // // console.log("city: ", city);
  //         // // console.log("state: ", state);
  //         // // console.log("country: ", country);
  //         // // console.log("address: ", address);

  //         if (country) {
  //           this.setState({ country:  country });
  //         }
  //         if (city) {
  //           this.setState({ city:  city });
  //         }
  //         else if (state) {
  //           this.setState({ city: state })
  //         }
  //         if (address) {
  //           this.setState({ address:  address });
  //         }
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }

  render() {
    const defaultProps = {
      center: {
        lat: -6.786525,
        lng: 39.274040
        // lat: -6.200056,
        // lng: 35.7297673
      },
      zoom: this.props.is_dashboard ? 5 : 11
    };

    const createMapOptions = (maps: GoogleMapReact.Maps) => {
      // next props are exposed at maps
      // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
      // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
      // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
      // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
      // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
      return {
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_CENTER,
          style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: true
      }
    }

    return (
      // Important! Always set the container height explicitly
      <div className="map-cont" >
        {/* {this.props.is_dashboard && <p className="loc-title-dashboard item-title">Locations</p>} */}
        {/* {!this.props.is_dashboard && <p className="loc-title item-title">Location</p>} */}

        {/* {!this.props.is_dashboard && !this.props.is_location_data_loaded && <div className="loading-map" />}
        {!this.props.is_dashboard && (!this.props.location || !this.props.location.lat || !this.props.location.lng || this.props.location.lat === 0 || this.props.location.lng === 0 ) && <div className="loading-map" />}
        
        {!this.props.is_dashboard && !this.props.is_dashboard && !this.props.is_location_data_loaded && <i className="fa fa-map-marker item-icon" aria-hidden="true"></i>}
        {!this.props.is_dashboard && (!this.props.location || !this.props.location.lat || !this.props.location.lng || this.props.location.lat === 0 || this.props.location.lng === 0 ) && <i className="fa fa-map-marker item-icon" aria-hidden="true"></i>} */}
        
        {/* {!this.props.is_dashboard && !this.props.is_location_data_loaded && <p className="loc-info">Loading...</p>} */}
        {/* {!this.props.is_dashboard && this.props.is_location_data_loaded && (!this.props.location || !this.props.location.lat || !this.props.location.lng || this.props.location.lat === 0 || this.props.location.lng === 0 ) && <p className="loc-info">No Location Data Available.</p>} */}

        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={createMapOptions}
        >
         { 
        //  this.props.is_location_data_loaded && 
         this.state.location && 
         this.state.location.lat !== 0 && 
         this.state.location.lng !== 0 && 
         <Marker 
          lat={this.state.location.lat} 
          lng={this.state.location.lng} 

          country={this.state.country}
          city={this.state.city}
          address={this.state.address}
          // last_location_updated={this.props.last_location_updated}
          /> }

        </GoogleMapReact>
      </div>
    );
  }
}

export class Marker extends React.Component<{ 
  // last_location_updated: number;
  lat: number;
  lng: number;
  country: string;
  city: string;
  address: string;

  // lat={-6.786525}
  // lng={39.274040}
},{}> { 
  render() { 

    // // console.log("Location: " + this.props.point.lat + ", " + this.props.point.lng + " | last updated: ", new Date(this.props.last_location_updated));
    // // console.log("Location: " + this.props.lat + ", " + this.props.lng);

    // function getDisplayTime(input_int: number) {
    //   return input_int < 10 ? ("0" + input_int) : input_int;
    // }

    return (
      <div className="marker-container">
        <div className="marker-modal" id="marker-modal" data-html2canvas-ignore="true">
          <div className="marker-modal-name">{this.props.country}</div>
            <p color="#666" className="marker-modal-address">{this.props.city}</p>
            <p 
            // href="https://www.google.com/maps/search/?api=1&amp;query=Tunisia%20Rd,%20Dar%20es%20Salaam,%20Tanzania" 
            // target="_blank" 
            color="#666" 
            // rel="noreferrer"
            className="marker-modal-address">{this.props.address}</p>
          <div className="marker-modal-notes" color="#393939">
            {/* <p>{this.props.address}</p> */}
            <p>Last Update:&nbsp;&nbsp; 
              {/* {
              (
                (new Date(this.props.last_location_updated)).getDate() === (new Date()).getDate() &&
                (new Date(this.props.last_location_updated)).getMonth() === (new Date()).getMonth() &&
                (new Date(this.props.last_location_updated)).getFullYear() === (new Date()).getFullYear() ?
                "Today" : 
                (new Date(this.props.last_location_updated)).toDateString()
              ) 
              + " at " + getDisplayTime((new Date(this.props.last_location_updated)).getHours()) + ":" + getDisplayTime((new Date(this.props.last_location_updated)).getMinutes()) + ":" + getDisplayTime((new Date(this.props.last_location_updated)).getSeconds())
             } */}
             </p>
          </div>
          <div className="marker-modal-tail"></div>
        </div>
        <div className="marker-pointer">
          <div className="marker-shape"></div>
        </div>
      </div>
    )
  }
}

//  title={"Currently Cooler / Fridge Location (lat: " + this.props.point.lat + ", lng: " + this.props.point.lng + ")"}
// {/* <div className="marker-info-window border-shadow">
//         <div className="marker-text">
//           <p>{this.props.text}</p>
//         </div>
//       </div> */}
// {/* <div className="Marker__Container-xmauu2-2 fiZRck"></div> */}