import './form-item.css';

import { FormObject } from './';
import { Link } from 'react-router-dom';
import React from 'react';
import { currentPage } from '../';
import { media_url } from '../../../service';

export default class FormListItem extends React.Component<{
  form: FormObject;
  selected: boolean;
}, {
  fields_count: number;
  default_image_url: string | null;
  default_image_loaded: boolean
}> {

  private didMount: boolean = false;
  private defaultImageUrl: string = require('../../../assets/images/form-icon.png');
  private prev_refresh_id: number = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      fields_count: 0,
      default_image_url: null,
      default_image_loaded: false,
    }
    this.updateFieldsCount = this.updateFieldsCount.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    // if (this.props.selected) {
    //   // console.log('form-item: did mount: ', this.props);
    // }
    this.updateFieldsCount();
  }

  componentDidUpdate(prevProps: any, _prevState: any) {
    // if (this.props.selected) {
    //   // console.log('form-item: on-previous: refreshId: ', this.prev_refresh_id);
    //   // console.log('form-item: on-updated : refreshId: ', prevProps.form.refreshId);
    //   // console.log('form-item: on-compare : refreshId: ', this.props.form.refreshId);
    //   // console.log('-------------------------------------');
    // }
    if (prevProps.form.refreshId !== this.prev_refresh_id) {
      this.prev_refresh_id = prevProps.form.refreshId;
      this.updateFieldsCount();
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  updateFieldsCount() {
    if (!this.didMount) return;
    let fields_count = 0;
    if (this.props.form.eventForm.field_1.length > 48) fields_count++;
    if (this.props.form.eventForm.field_2.length > 48) fields_count++;
    if (this.props.form.eventForm.field_3.length > 48) fields_count++;
    if (this.props.form.eventForm.field_4.length > 48) fields_count++;
    if (this.props.form.eventForm.field_5.length > 48) fields_count++;
    if (this.props.form.eventForm.field_6.length > 48) fields_count++;
    if (this.props.form.eventForm.field_7.length > 48) fields_count++;
    this.setState({ fields_count: fields_count });
  }

  render() {
    return (
      <Link
        to={currentPage.FORMS + "/" + this.props.form.eventForm.event_id}
        className={this.props.selected ? "form-item-cont item-selected" : "form-item-cont"}>
        <div className="form-item-image-cont">
          <div className="form-item-image-top" />
          <img
            className="form-item-image"
            src={this.state.default_image_url ? this.state.default_image_url :
              (this.props.form.eventForm.event_image_title && this.props.form.eventForm.event_image_title ?
                media_url + this.props.form.eventForm.event_image_title : this.defaultImageUrl)}
            onError={(e) => {
              if (this.state.default_image_loaded) return;
              this.setState({ default_image_url: this.defaultImageUrl, default_image_loaded: true });
            }}
            alt="event profile image"
            crossOrigin="anonymous"
            referrerPolicy='origin-when-cross-origin'
          />
        </div>
        <div className="form-item-info">
          <div className="form-item-info-title">{this.props.form.eventForm.event_name}</div>
          <div className="form-item-bottom-cont">
            <div className="form-item-count">
              <span className='form-item-cont-text'>{this.state.fields_count}</span>
              <span className='form-item-cont-title'>{this.state.fields_count === 1 ? 'Field' : 'Fields'}</span>
            </div>
            {/* <div className="form-item-count">
                <i className="fas fa-user-check attended-icon item-icon"></i>
                <span>{this.props.form.eventForm.attended_count}</span>
              </div> */}
          </div>
        </div>
      </Link>
    );
  }
}