import './event-details.css';

import DatabaseManager, { media_url } from '../../../service';

import { Link } from 'react-router-dom';
import Map from '../../map';
import React from 'react';
import { UserEvent } from '../../models';
import { currentPage } from '../';

export default class EventDetails extends React.PureComponent<{
  user_id: string | null;
  event_id: string | null;
  current_page: string;
  onEditClick(event_id: string): void;
  refreshId: number;
}, {
  event: UserEvent | null,
  start_date: string,
  end_date: string,
  location_lat: number,
  location_lng: number,
  isLoading: boolean,
  data_loaded: boolean,
  default_event_image: any;
  default_image_loaded: boolean,
}> {

  private default_event_profile_image: string = require('../../../assets/images/event-icon.png');
  private previous_event_id: string | null;
  private didMount: boolean = false;
  private databaseManager: DatabaseManager;
  private refreshId: number = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      event: null,
      start_date: "",
      end_date: "",
      location_lat: 0,
      location_lng: 0,
      isLoading: true,
      data_loaded: false,
      default_event_image: this.default_event_profile_image,
      default_image_loaded: false,
    };
    this.databaseManager = new DatabaseManager();
    this.previous_event_id = this.props.event_id;
    this.refreshId = this.props.refreshId;
  }

  componentDidMount() {
    this.didMount = true;
    this.loadData();
  }

  componentDidUpdate(prevProps: any, _prevState: any) {
    if (!this.didMount) return;
    // console.log("event details: update: props: ", this.props);
    if (!this.props.user_id || !this.props.event_id) return;
    if (this.props.event_id !== this.previous_event_id) {
      this.previous_event_id = this.props.event_id;
      this.setState({ data_loaded: false }); // reload data
      setTimeout(() => {
        if (!this.didMount) return;
        this.loadData();
      }, 100);
    }
    if (this.refreshId !== this.props.refreshId) {
      this.refreshId = this.props.refreshId;
      this.setState({ data_loaded: false }); // reload data
      setTimeout(() => {
        if (!this.didMount) return;
        this.loadData();
      }, 100);
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    // // console.log("event details: loadData: state: ", this.state);
    if (!this.didMount) return;
    if (!this.props.user_id || !this.props.event_id) return;
    if (this.state.data_loaded) return;
    // // console.log("event details: loadData: called");
    this.databaseManager.getSingleEvent(this.props.user_id, this.props.event_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
        if (result) {
          if (result.success && result.success.data && result.success.data.length > 0) {
            // // console.log("data: ", result.success.data);
            const data = result.success.data[0];
            const userEvent: UserEvent = {
              id: data.id ? data.id : "",
              name: data.name ? data.name : "Event",
              description: data.description ? data.description : "No description",
              about: data.about ? data.about : "No about",
              venue: data.venue ? data.venue : "No venue or location name",
              image_title: data.image_title ? data.image_title : null,
              artwork_title: data.artwork_title ? data.artwork_title : null,
              location_latlng: data.location_latlng ? data.location_latlng : null,
              applicants_count: data.applicants_count ? data.applicants_count : 0,
              attended_count: data.applicants_count ? data.applicants_count : 0,
              created_date: data.created_date ? data.created_date : 0,
              start_date: data.start_date ? data.start_date : 0,
              end_date: data.end_date ? data.end_date : 0,
            }
            const start_date = new Date(userEvent.start_date);
            const end_date = new Date(userEvent.end_date);
            const location_latlng = userEvent.location_latlng ? JSON.parse(userEvent.location_latlng) : { lat: 0, lng: 0 };
            this.setState({
              event: userEvent,
              start_date: start_date.toLocaleString(),
              end_date: end_date.toLocaleString(),
              location_lat: location_latlng.lat ? location_latlng.lat : 0,
              location_lng: location_latlng.lng ? location_latlng.lng : 0,
            });
          } else {
            this.setState({ isLoading: false, data_loaded: false });
          }
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
      }
    });
  }

  render() {
    return (
      <div className="event-details"
        style={{
          display: this.props.current_page === currentPage.EVENTS + "/" + this.props.event_id ? 'flex' : 'none'
        }}>
        {this.state.event &&
          <>
            <div className="event-details-back-btn">
              <i className="fas fa-arrow-left event-details-back-btn-icon"
                onClick={(e: any) => {
                  e.preventDefault();
                  window.history.back();
                }}></i>
              <button className='event-details-edit-btn'
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount || !this.props.event_id) return;
                  this.props.onEditClick(this.props.event_id);
                  this.loadData();
                }}>Edit</button>
            </div>
            <div className="event-details-main">
              <div className="event-details-top-cont"
                style={{
                  backgroundImage: this.state.event && this.state.event.image_title ?
                    `url(${media_url + this.state.event.image_title})` :
                    `url(${this.default_event_profile_image})`
                }}
              ></div>
              <div className="event-details-image-cont" />
              <div className="event-details-image-top" />
              {((this.state.event.image_title) ||
                this.state.default_event_image) &&
                <img
                  className="event-details-image"
                  src={
                    this.state.default_image_loaded ? this.state.default_event_image :
                      ((this.state.event && this.state.event.image_title) ?
                        (media_url + this.state.event.image_title) : this.state.default_event_image)
                  }
                  alt="event profile image"
                  crossOrigin="anonymous"
                  referrerPolicy='origin-when-cross-origin'
                  onError={(_e) => {
                    if (!this.didMount || this.state.default_image_loaded) return;
                    this.setState({
                      default_image_loaded: true,
                      default_event_image: this.default_event_profile_image,
                    });
                  }}
                />
              }
              <div className="event-details-main-cont">
                <span className="event-details-title">{this.state.event.name}</span>
                <div className="event-details-action-cont">
                  <div className="event-details-action-item">
                    <Link to={currentPage.ARTWORKS + "/" + this.state.event.id} className="event-details-action-item-btn">
                      <i className="fas fa-images event-details-action-icon"></i>
                      <span className="event-details-action-text">Artwork</span>
                    </Link>
                  </div>
                  <div className="event-details-action-item">
                    <Link to={currentPage.FORMS + "/" + this.state.event.id} className="event-details-action-item-btn">
                      <i className="fas fa-file-alt event-details-action-icon"></i>
                      <span className="event-details-action-text">Form</span>
                    </Link>
                  </div>
                  <div className="event-details-action-item">
                    <Link to={currentPage.REPORTS + "/" + this.state.event.id} className="event-details-action-item-btn">
                      <i className="fas fa-chart-bar event-details-action-icon"></i>
                      <span className="event-details-action-text">Reports</span>
                    </Link>
                  </div>
                </div>
                <div className="event-details-action-cont">
                  <div className="event-details-action-item">

                  </div>
                  <div className="event-details-action-item">
                    <Link className="event-details-action-item-btn"
                      to={currentPage.REGISTER + "?i=" + this.state.event.id}
                      target="_blank">
                      <i className="fas fa-link event-details-action-icon"></i>
                      <span className="event-details-action-text">Registration Link</span>
                    </Link>
                  </div>
                  <div className="event-details-action-item">
                    <div className="event-details-action-item-btn">
                      <i className="fas fa-share-alt event-details-action-icon"></i>
                      <span className="event-details-action-text">Share</span>
                    </div>
                  </div>
                </div>
                <div className='event-details-counts-cont'>
                  <div className='event-details-counts-item item-0'>
                    <span className='event-details-counts-title'>{this.state.event.applicants_count}</span>
                    <span className='event-details-counts'>{this.state.event.applicants_count === 1 ? 'Applicant' : 'Applicants'}</span>
                    <Link to={currentPage.APPLICANTS + '/' + this.state.event.id} className='event-details-counts-btn'>See All</Link>
                  </div>
                  <div className='event-details-counts-item item-1'>
                    <span className='event-details-counts-title'>{this.state.event.applicants_count}</span>
                    <span className='event-details-counts'>{'Attended'}</span>
                    <Link to={currentPage.APPLICANTS + '/' + this.state.event.id + '/attended'} className='event-details-counts-btn'>See All</Link>
                  </div>
                </div>
                <div className='event-details-date-cont'>
                  <i className="far fa-calendar-alt event-details-date-icon"></i>
                  <span className="event-details-date">Start Date: &nbsp;{this.state.start_date}</span>
                  <span className="event-details-date">End Date: &nbsp;&nbsp;{this.state.end_date}</span>
                </div>
                <span className="event-details-desc">{this.state.event.description}</span>
                <span className="event-details-desc">{this.state.event.about}</span>
                <div className="event-details-location-cont">
                  <i className="fas fa-map-marker-alt location-icon"></i>
                  <span className="location-name">{this.state.event.venue}</span>
                  {/* {this.state.location_lat !== 0 && this.state.location_lng !== 0 && */}
                  <div className='event-details-map-cont'>
                    <Map
                      is_dashboard={false}
                    // locations={[]}
                    // is_location_data_loaded={!this.state.dashboard_loading}
                    // location={undefined}
                    // last_location_updated={0}
                    />
                  </div>
                  {/* } */}
                </div>
                <div className='event-details-bottom-cont'>
                  <button className='delete-event-btn'>Delete Event</button>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    );
  }
} 