import 'rsuite/dist/rsuite.min.css';
import './index.css';
import './event-item.css';
import './event-new.css';
import './event-details.css';

import DatabaseManager, { media_url } from '../../../service';

import { EventObject } from './';
import { Link } from 'react-router-dom';
import React from 'react';
import { currentPage } from '../';

export default class EventListItem extends React.Component<{
    event: EventObject;
    selected: boolean;
    refreshId: number;
}, {
    default_event_image: any;
    default_image_loaded: boolean;
}> {

    private default_event_profile_image: string = require('../../../assets/images/event-icon.png');
    private didMount: boolean = false;
    private refreshId: number = 0;

    constructor(props: any) {
        super(props);

        this.state = {
            default_event_image: this.props.event.userEvent.image_title || this.default_event_profile_image,
            default_image_loaded: false,
        }
        this.refreshId = this.props.refreshId;
    }

    componentDidMount() {
        this.didMount = true;
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.refreshId !== this.refreshId) {
            this.refreshId = this.props.refreshId;
            this.setState({ default_image_loaded: false });
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        this.didMount = false;
    }

    render() {
        return (
            <Link
                to={currentPage.EVENTS + "/" + this.props.event.userEvent.id}
                className={this.props.selected ? "event-item-cont item-selected" : "event-item-cont"}>
                <div className="event-item-image-cont">
                    <div className="event-item-image-top" />
                    {(this.props.event.userEvent.image_title || this.state.default_event_image) &&
                        <img
                            className="event-item-image"
                            src={
                                this.state.default_image_loaded ? this.state.default_event_image :
                                    (this.props.event.userEvent.image_title ? (media_url + this.props.event.userEvent.image_title) : this.state.default_event_image)
                            }
                            alt="event profile image"
                            crossOrigin="anonymous"
                            referrerPolicy='origin-when-cross-origin'
                            onError={(_e) => {
                                if (!this.didMount || this.state.default_image_loaded) return;
                                this.setState({
                                    default_image_loaded: true,
                                    default_event_image: this.default_event_profile_image,
                                });
                            }}
                        />
                    }
                </div>
                <div className="event-item-info">
                    <div className="event-item-info-title">{this.props.event.userEvent.name}</div>
                    <div className="event-item-info-desc">{this.props.event.userEvent.description}</div>
                    <div className="event-item-bottom-cont">
                        <div className="event-item-count">
                            <i className="fas fa-users applicants-icon item-icon"></i>
                            <span>{this.props.event.userEvent.attended_count}</span>
                        </div>
                        <div className="event-item-count">
                            <i className="fas fa-user-check attended-icon item-icon"></i>
                            <span>{this.props.event.userEvent.attended_count}</span>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}
