import './index.css';

import React from 'react';
import { v4 as uuidV4 } from 'uuid';

export enum LoadingType {
    EVENTS,
    FORMS,
    APPLICANTS,
    ARTWORKS,
    REPORTS,
    ACCOUNT,
    HELP,
}

export default class Loading extends React.PureComponent<{
    type: LoadingType;
    show: boolean;
}> {

    private item_list: Array<string> = [];

    constructor(props: any) {
        super(props);

        for (let i = 0; i < 5; i++) {
            this.item_list.push(uuidV4());
        }
    }

    render() {
        return (
            <>
                {
                    this.props.show &&
                    <div className="loading-cont">
                        {
                            this.props.type === LoadingType.EVENTS ?
                                <>
                                    {
                                        this.item_list.map((_item) => (
                                            <div className="event-loading-item" key={_item}>
                                                <div className="event-image loading-shimmer" />
                                                <div className="event-info">
                                                    <div className="event-info-title loading-shimmer" />
                                                    <div className="event-info-desc loading-shimmer" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </> :
                                <>
                                    {
                                        this.item_list.map((_item) => (
                                            <div className="attendee-loading-item" key={_item}>
                                                <div className="attendee-image loading-shimmer" />
                                                <div className="attendee-info">
                                                    <div className="attendee-info-title loading-shimmer" />
                                                    <div className="attendee-info-desc loading-shimmer" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
                }
            </>

        )
    }
}