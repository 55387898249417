import './index.css';

import AuthContext from '../../../service/context';
import DatabaseManager from '../../../service';
import { Link } from 'react-router-dom';
import React from 'react';
import { currentPage } from '../';

export default class Header extends React.Component<{
    onNavMenuCluck(): void;
    user_name: string | null;
    nav_modal_active: boolean;
    header_title: string;
}, {}> {

    private didMount: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            user_name: null,
        }

        this.onNavMenuClick = this.onNavMenuClick.bind(this);
    }

    componentDidMount() {
        this.didMount = true;
    }

    componentWillUnmount() {
        this.didMount = false;
    }

    onNavMenuClick() {
        if (!this.didMount) return;
        this.props.onNavMenuCluck();
    }

    render() {
        return (
            <div className={
                this.props.nav_modal_active ?
                    "main-header cont-fwm" :
                    "main-header"
            }>
                <i className="fas fa-bars menu-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        this.onNavMenuClick();
                    }}
                ></i>
                <span className="app-title">{this.props.header_title}</span>

                <Link to={currentPage.ACCOUNT} className="user-lay" title={this.props.user_name ? this.props.user_name : "User"}>
                    <p className="user-name">{this.props.user_name ? this.props.user_name : "User"}</p>
                    <i className="fas fa-user user-icon"></i>
                </Link>

                <div className="pallete-container">
                    <label className="switch" title="Change theme">
                        <input type="checkbox" className="checkbox" id="theme-btn" />
                        <span className="toggle-thumb">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
                            </svg>
                        </span>
                    </label>
                </div>
            </div>
        );
    }
}

export class SideNav extends React.Component<{
    history: any;
    current_page: string;
}, {}> {

    static contextType = AuthContext;
    private databaseManager: DatabaseManager;

    constructor(props: any) {
        super(props);
        this.databaseManager = new DatabaseManager();
        this.onLogOutBtnClick = this.onLogOutBtnClick.bind(this);
    }

    onLogOutBtnClick() {
        this.databaseManager.logoutUser(
            {
                onResult: (_result: any) => {
                    this.setState({ isLoading: false });
                    this.context.setIsAuthenticated(false);
                    // window.location.reload();
                    // if (result) {
                    //     this.props.history.push('/')
                    // }
                },
                onError: (_error: any) => {
                }
            });
    }

    render() {
        return (
            <div className="side-nav">
                <div className="side-nav-header">
                    <Link className="home-btn" to="/">
                        <img className="header-logo-img" src={require('../../../assets/images/logo.png')} alt="logo" />
                        {/* <i className="fas fa-home home-icon"></i> */}
                        <div className="header-text-cont">
                            <span>Laibon</span>
                            <span>Registration</span>
                        </div>
                    </Link>
                </div>
                <div className="side-nav-btns-cont">
                    {/* <div className="side-nav-large-lay" /> */}
                    <Link className={
                        this.props.current_page.startsWith(currentPage.DASHBOARD) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.DASHBOARD}>
                        <i className="fas fa-tachometer-alt dashboard-icon side-nav-icon"></i>
                        <span>Dashboard</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.EVENTS) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.EVENTS}>
                        <i className="fas fa-calendar-alt events-icon side-nav-icon"></i>
                        <span>Events</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.FORMS) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.FORMS}>
                        <i className="fas fa-list-alt forms-icon side-nav-icon"></i>
                        <span>Forms</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.ARTWORKS) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.ARTWORKS}>
                        <i className="fas fa-images artworks-icon side-nav-icon"></i>
                        <span>Artworks</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.APPLICANTS) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.APPLICANTS}>
                        <i className="fas fa-users forms-icon side-nav-icon"></i>
                        <span>Applicants</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.REPORTS) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.REPORTS}>
                        <i className="fas fa-chart-bar forms-icon side-nav-icon"></i>
                        <span>Reports</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.ACCOUNT) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.ACCOUNT}>
                        <i className="fas fa-user forms-icon side-nav-icon"></i>
                        <span>Account</span>
                    </Link>
                    <Link className={
                        this.props.current_page.startsWith(currentPage.HELP) ?
                            "side-nav-btn btn-active" :
                            "side-nav-btn"} to={currentPage.HELP}>
                        <i className="fas fa-question-circle forms-icon side-nav-icon"></i>
                        <span>Help</span>
                    </Link>
                    <div className='footer-cont'>
                        <div className='footer-seg'>
                            <Link className='footer-link' to='/' target='_blank'>Home</Link>
                            <Link className='footer-link' to='/privacy' target='_blank'>Privacy</Link>
                            <Link className='footer-link' to='/terms' target='_blank'>Terms</Link>
                        </div>
                        <div className='footer-seg'><span className='footer-text'>Copyright &copy;</span></div>
                        <div className='footer-seg'><a className='footer-link' href='https://imperialinnovations.co.tz' target='_blank'>Imperial Innovations</a></div>
                        <div className='footer-seg'><span className='footer-text'>2022</span></div>
                        <div className='footer-seg'><span className='footer-text'>All Rights Reserved</span></div>
                    </div>
                </div>

                <div className="logout-btn-cont" >
                    <div className="side-nav-btn"
                        onClick={(e) => {
                            this.onLogOutBtnClick();
                        }}>
                        <i className="fas fa-sign-out-alt logout-icon side-nav-icon"></i>
                        <span>Logout</span>
                    </div>
                </div>
            </div>
        );
    }
}