import './index.css';

import * as H from 'history';

import { Link, match, withRouter } from 'react-router-dom';
import { RegistrationForm, RegistrationFormField } from '../models';

import DatabaseManager from '../../service';
import { Input } from 'rsuite';
import React from 'react';
import { currentPage } from '../main';
import { media_url } from '../../service';

class Registration extends React.Component<{
    history: H.History;
    location: H.Location;
    match: match;
},
    {
        event_id: string | null,
        form: RegistrationForm | null,
        event_date: string,
        can_register: boolean,
        error: string | null,
        isLoading: boolean,
        show_registration_form: boolean,
        fields: RegistrationFormField[],
    }> {

    private didMount: boolean = false;
    private databaseManager: DatabaseManager = new DatabaseManager();

    constructor(props: any) {
        super(props);
        this.state = {
            event_id: null,
            form: null,
            event_date: '',
            can_register: false,
            error: null,
            isLoading: true,
            show_registration_form: false,
            fields: [],
        }
    }

    componentDidMount() {
        this.didMount = true;
        // change page title
        document.title = "LR  - Registration";
        // console.log('path: ', document.location.pathname);
        // get event id from path in /registration/:event_id or /register?id=:event_id
        const event_id = this.props.history.location.pathname.split('/')[2] || this.props.history.location.search.split('=')[1] || null;
        // console.log('registration: event_id', event_id);
        this.setState({ event_id: event_id });
        if (!event_id) {
            this.props.history.replace(currentPage.LANDING);
        }
        setTimeout(() => this.loadData(), 100);

        const _theme = localStorage.getItem('theme');
        if (_theme) document.documentElement.className = _theme;

        window.addEventListener('storage', (event: StorageEvent) => {
            if (!this.didMount) return;
            if (event.key === 'theme') {
                let theme = event.newValue;
                if (theme)
                    document.documentElement.className = theme;
            }
        });
    }

    componentWillUnmount() {
        this.didMount = false;
        // remove event listener
        window.removeEventListener('storage', (event: StorageEvent) => { });
    }

    loadData() {
        if (!this.didMount) return;
        if (!this.state.event_id) return;
        this.setState({ isLoading: true });

        this.databaseManager.getRegistrationForm(this.state.event_id, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                if (result.success) {
                    const data = result.success.data && result.success.data.length > 0 ? result.success.data[0] : null;
                    // console.log('registration form: ', data);
                    this.setState({ form: result.data });
                    const regForm: RegistrationForm = {
                        id: data.id ? data.id : '',
                        name: data.name ? data.name : '',
                        description: data.description ? data.description : '',
                        about: data.about ? data.about : null,
                        venue: data.venue ? data.venue : '',
                        image_title: data.image_title ? data.image_title : null,
                        artwork_title: data.artwork_title ? data.artwork_title : null,
                        location_latlng: data.location_latlng ? data.location_latlng : null,
                        field_1: data.field_1 ? data.field_1 : '',
                        field_2: data.field_2 ? data.field_2 : '',
                        field_3: data.field_3 ? data.field_3 : '',
                        field_4: data.field_4 ? data.field_4 : '',
                        field_5: data.field_5 ? data.field_5 : '',
                        field_6: data.field_6 ? data.field_6 : '',
                        field_7: data.field_7 ? data.field_7 : '',
                        created_date: data.created_date ? data.created_date : 0,
                        start_date: data.start_date ? data.start_date : 0,
                        end_date: data.end_date ? data.end_date : 0,
                        current_date: data.current_date ? data.current_date : 0,
                    }
                    this.setState({ form: regForm });

                    if (regForm.start_date > 0 && regForm.end_date > 0) {
                        const start_date = new Date(regForm.start_date);
                        const end_date = new Date(regForm.end_date);

                        // reference https://www.tabnine.com/academy/javascript/how-to-format-date/
                        // // console.log(date.toLocaleString('en-US', {
                        //     weekday: 'short', // long, short, narrow
                        //     day: 'numeric', // numeric, 2-digit
                        //     year: 'numeric', // numeric, 2-digit
                        //     month: 'long', // numeric, 2-digit, long, short, narrow
                        //     hour: 'numeric', // numeric, 2-digit
                        //     minute: 'numeric', // numeric, 2-digit
                        //     second: 'numeric', // numeric, 2-digit
                        // })); // Output: Tue, July 21, 2020, 10:01:14 AM

                        const event_date = `
                        ${start_date.toLocaleString(undefined, {
                            weekday: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            month: 'long',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                        })} 
                        - ${end_date.toLocaleString(undefined, {
                            weekday: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            month: 'long',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                        })} 
                        `;
                        this.setState({ event_date: event_date });
                    }
                    // multiple cases here first
                    const can_register = regForm.current_date > 0 && regForm.current_date < regForm.end_date;
                    // check if any of fileds is not empty
                    const can_register_2 = regForm.field_1.length > 0 || regForm.field_2.length > 0 || regForm.field_3.length > 0 || regForm.field_4.length > 0 || regForm.field_5.length > 0 || regForm.field_6.length > 0 || regForm.field_7.length > 0;
                    if (!can_register) {
                        this.setState({ error: 'Registration is closed.' });
                        // this.setState({ error: 'Registration is closed.\n\nThe registration time for this event is ended.' });
                    }
                    else if (!can_register_2) {
                        this.setState({ error: 'The registration form for this event is not ready yet.\n\nPlease check back later.' });
                    }
                    else {
                        this.setState({ error: null });
                    }
                    this.setState({ can_register: can_register && can_register_2 });

                    if (can_register && can_register_2) {
                        const fields: Array<RegistrationFormField> = [];
                        if (regForm.field_1.length > 0) {
                            const field_1 = JSON.parse(regForm.field_1);
                            fields.push({
                                field: {
                                    title: field_1.title ? field_1.title : '',
                                    name: field_1.name ? field_1.name : '',
                                    type: field_1.type ? field_1.type : '',
                                    unique: field_1.unique ? field_1.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        if (regForm.field_2.length > 0) {
                            const field_2 = JSON.parse(regForm.field_2);
                            fields.push({
                                field: {
                                    title: field_2.title ? field_2.title : '',
                                    name: field_2.name ? field_2.name : '',
                                    type: field_2.type ? field_2.type : '',
                                    unique: field_2.unique ? field_2.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        if (regForm.field_3.length > 0) {
                            const field_3 = JSON.parse(regForm.field_3);
                            fields.push({
                                field: {
                                    title: field_3.title ? field_3.title : '',
                                    name: field_3.name ? field_3.name : '',
                                    type: field_3.type ? field_3.type : '',
                                    unique: field_3.unique ? field_3.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        if (regForm.field_4.length > 0) {
                            const field_4 = JSON.parse(regForm.field_4);
                            fields.push({
                                field: {
                                    title: field_4.title ? field_4.title : '',
                                    name: field_4.name ? field_4.name : '',
                                    type: field_4.type ? field_4.type : '',
                                    unique: field_4.unique ? field_4.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        if (regForm.field_5.length > 0) {
                            const field_5 = JSON.parse(regForm.field_5);
                            fields.push({
                                field: {
                                    title: field_5.title ? field_5.title : '',
                                    name: field_5.name ? field_5.name : '',
                                    type: field_5.type ? field_5.type : '',
                                    unique: field_5.unique ? field_5.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        if (regForm.field_6.length > 0) {
                            const field_6 = JSON.parse(regForm.field_6);
                            fields.push({
                                field: {
                                    title: field_6.title ? field_6.title : '',
                                    name: field_6.name ? field_6.name : '',
                                    type: field_6.type ? field_6.type : '',
                                    unique: field_6.unique ? field_6.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        if (regForm.field_7.length > 0) {
                            const field_7 = JSON.parse(regForm.field_7);
                            fields.push({
                                field: {
                                    title: field_7.title ? field_7.title : '',
                                    name: field_7.name ? field_7.name : '',
                                    type: field_7.type ? field_7.type : '',
                                    unique: field_7.unique ? field_7.unique : '',
                                },
                                value_1: '', value_2: '',
                            });
                        }
                        this.setState({ fields: fields });
                    }
                }
            },
            onError: (_error: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
            }
        });
    }

    private formField(
        index: number,
        field: RegistrationFormField,
        onValue1Change: (e1: any, index: number) => void,
        onValue2Change: (e2: any, index: number) => void
    ): JSX.Element {
        let input_element: JSX.Element;
        switch (field.field.type) {
            case 'text':
                input_element = (
                    <input
                        className="field-input"
                        type="text"
                        value={field.value_1}
                        placeholder={field.field.name}
                        autoComplete="off"
                        onChange={(e) => onValue1Change(e, index)} />
                );
                break;
            case 'number':
                input_element = (
                    <input
                        className="field-input"
                        type="number"
                        value={field.value_1}
                        placeholder={field.field.name}
                        autoComplete="off"
                        onChange={(e) => onValue1Change(e, index)} />
                );
                break;
            case 'email':
                input_element = (
                    <input
                        className="field-input"
                        type="email"
                        value={field.value_1}
                        placeholder={field.field.name}
                        autoComplete="off"
                        onChange={(e) => onValue1Change(e, index)} />
                );
                break;
            case 'date':
                input_element = (
                    <input
                        className="field-input"
                        type="date"
                        value={field.value_1}
                        placeholder={field.field.name}
                        autoComplete="off"
                        onChange={(e) => onValue1Change(e, index)} />
                );
                break;
            case 'time':
                input_element = (
                    <input
                        className="field-input"
                        type="time"
                        value={field.value_1}
                        placeholder={field.field.name}
                        autoComplete="off"
                        onChange={(e) => onValue1Change(e, index)} />
                );
                break;
            case 'date-and-time':
                input_element = (
                    <>
                        <input
                            className="field-input"
                            type="date"
                            value={field.value_1}
                            placeholder={field.field.name}
                            autoComplete="off"
                            onChange={(e) => onValue1Change(e, index)} />
                        <input
                            className="field-input"
                            type="time"
                            value={field.value_2}
                            placeholder={field.field.name}
                            autoComplete="off"
                            onChange={(e) => onValue2Change(e, index)} />
                    </>
                );
                break;
            case 'gender':
                input_element = (
                    <select
                        className="field-input"
                        value={field.value_1}
                        onChange={(e) => onValue1Change(e, index)}>
                        <option value="">{field.field.name}</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                );
                break;

            default:
                input_element = <></>;
        }

        return (
            <div className="field-item" key={index + ""}>
                <div className="field-label">{field.field.title}</div>
                <div className="field-inputs">{input_element}</div>
            </div>
        );
    }


    render() {
        return (
            <>
                <div className="reg-main-bg" tabIndex={-1} style={{ backgroundImage: this.state.form ? `url(${media_url + this.state.form.image_title})` : `none` }} />
                {/* <div className="registration-main-lay-back" /> */}
                <div className="registration-main-lay" >
                    {/* <div className="registration-main-lay-bg" style={{ backgroundImage: this.state.form ? `url(${media_url + this.state.form.image_title})` : `none` }} /> */}
                    {
                        this.state.isLoading && <div className="loading-cont">
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw loading-icon"></i>
                        </div>
                    }
                    {
                        !this.state.isLoading && !this.state.form &&
                        <div className="error-general-cont">
                            <i className="fa fa-exclamation-triangle fa-3x fa-fw error-general-icon"></i>
                            <div className="error-general-text">Sorry, we cannot find the event you are looking for.</div>
                            <Link to={currentPage.LANDING} className="error-general-btn">Back to Home</Link>
                        </div>
                    }
                    {
                        this.state.form &&
                        <div className="registration-main-content">
                            <div className="registration-main-header" />
                            {
                                this.state.form.image_title &&
                                <div className="registration-main-top-lay"
                                    style={{ backgroundImage: `url(${media_url + this.state.form.image_title})` }}>
                                    <div className="registration-main-top" />
                                    {/* <img className="registration-main-top-img" src={media_url + this.state.form.image_title} /> */}
                                </div>
                            }
                            <div className="registration-info">
                                <div className="registration-title">{this.state.form.name}</div>
                                <div className="registration-date-lay">
                                    <i className="fa fa-clock fa-fw date-icon"></i>
                                    <div className="registration-date">{this.state.event_date}</div>
                                </div>
                                <div className="registration-about">{this.state.form.about ? this.state.form.about : this.state.form.description}</div>
                                <div className="registration-info-bottom">
                                    {
                                        this.state.can_register &&
                                        <div className="registration-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!this.didMount) return;
                                                this.setState({ show_registration_form: true });
                                                setTimeout(() => {
                                                    const form_element = document.getElementById('reg-form-cont');
                                                    if (form_element) {
                                                        form_element.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'start'
                                                        });
                                                    }
                                                }, 100);
                                            }}>
                                            Register Now
                                        </div>
                                    }
                                    {this.state.error && <div className="registration-error">{this.state.error}</div>}
                                </div>
                            </div>
                            {
                                this.state.show_registration_form &&
                                <section id="reg-form-cont" className="reg-form-cont">
                                    <div className="reg-form-header">
                                        <div className="reg-form-title">Registration Form</div>
                                    </div>
                                    <div className="reg-form-body">
                                        {
                                            this.state.fields.map((field, index) => {
                                                return this.formField(
                                                    index,
                                                    field,
                                                    (e, index) => {
                                                        if (!this.didMount) return;
                                                        const fields = [...this.state.fields];
                                                        fields[index].value_1 = e.target.value;
                                                        this.setState({ fields: fields });
                                                        this.forceUpdate();
                                                    },
                                                    (e, index) => {
                                                        if (!this.didMount) return;
                                                        const fields = [...this.state.fields];
                                                        fields[index].value_2 = e.target.value;
                                                        this.setState({ fields: fields });
                                                        this.forceUpdate();
                                                    },
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="reg-form-footer">
                                        <div className="reg-form-close-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ show_registration_form: false });
                                            }}>
                                            <i className="fa fa-times fa-fw close-icon"></i>
                                            <span className="close-text">Cancel</span>
                                        </div>
                                        <div className="reg-form-submit-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!this.didMount) return;
                                                // this.setState({ isLoading: true });
                                                // this.submitRegistration();
                                            }}>
                                            Submit
                                        </div>
                                    </div>
                                </section>
                            }
                        </div>
                    }
                    <div className='footer-cont'>
                        <div className='footer-seg'>
                            <Link className='footer-link' to='/' target='_blank'>Home</Link>
                            <Link className='footer-link' to='/privacy' target='_blank'>Privacy</Link>
                            <Link className='footer-link' to='/terms' target='_blank'>Terms</Link>
                        </div>
                        <div className='footer-seg'>
                            <span className='footer-text'>Copyright &copy;</span>
                            <a className='footer-link' href='https://imperialinnovations.co.tz' target='_blank'>Imperial Innovations</a>
                            <span className='footer-text'>| 2022</span>
                        </div>
                        <div className='footer-seg'>
                            <span className='footer-text'>All Rights Reserved</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Registration);