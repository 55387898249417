import './form-details.css';

import { EventForm, EventFormField } from '../../models';

import DatabaseManager from '../../../service';
import React from 'react';
import { currentPage } from '../';
import { v4 as uuidV4 } from 'uuid';

export default class FormDetails extends React.PureComponent<{
  user_id: string | null;
  event_id: string | null;
  current_page: string;
  onFormUpdated(form: EventForm): void;
}, {
  event_id: string | null,
  fields: Array<EventFormField>,
  is_saving: boolean,
  error: string | null,
  isLoading: boolean,
  data_loaded: boolean,
}> {

  private previous_event_id: string | null;
  private didMount: boolean = false;
  private databaseManager: DatabaseManager;
  private eventForm: EventForm | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      event_id: null,
      fields: [],
      is_saving: false,
      error: null,
      isLoading: true,
      data_loaded: false,
    };
    this.databaseManager = new DatabaseManager();
    this.previous_event_id = this.props.event_id;
    this.onAddBtnClick = this.onAddBtnClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onRemoveFieldClick = this.onRemoveFieldClick.bind(this);
    this.onOkBtnClick = this.onOkBtnClick.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    this.loadData();
  }

  componentDidUpdate() {
    if (!this.didMount) return;
    // // console.log("form details: update: props: ", this.props);
    if (!this.props.user_id || !this.props.event_id) return;
    if (this.props.event_id !== this.previous_event_id) {
      this.previous_event_id = this.props.event_id;
      this.setState({ data_loaded: false }); // reload data
      setTimeout(() => {
        if (!this.didMount) return;
        this.loadData();
      }, 100);
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    // // console.log("form details: loadData: state: ", this.state);
    if (!this.didMount) return;
    if (!this.props.user_id || !this.props.event_id) return;
    if (this.state.data_loaded) return;
    this.databaseManager.getSingleForm(this.props.user_id, this.props.event_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        try {
          this.setState({ isLoading: false, data_loaded: true });
          if (result) {
            if (result.success && result.success.data && result.success.data.length > 0) {
              // // console.log("data: ", result.success.data);
              const data = result.success.data[0];
              const eventForm: EventForm = {
                event_id: data.event_id ? data.event_id : "",
                event_name: data.event_name ? data.event_name : "",
                created_date: data.created_date ? data.created_date : 0,
                event_image_title: data.event_image_title ? data.event_image_title : "",
                field_1: data.field_1 && data.field_1.length > 0 ? data.field_1 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}",
                field_2: data.field_2 && data.field_2.length > 0 ? data.field_2 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}",
                field_3: data.field_3 && data.field_3.length > 0 ? data.field_3 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}",
                field_4: data.field_4 && data.field_4.length > 0 ? data.field_4 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}",
                field_5: data.field_5 && data.field_5.length > 0 ? data.field_5 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}",
                field_6: data.field_6 && data.field_6.length > 0 ? data.field_6 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}",
                field_7: data.field_7 && data.field_7.length > 0 ? data.field_7 : "{\"title\":\"\",\"name\":\"\",\"type\":\"\",\"unique\":\"\"}"
              }

              const field_1_json: EventFormField = JSON.parse(eventForm.field_1);
              const field_2_json: EventFormField = JSON.parse(eventForm.field_2);
              const field_3_json: EventFormField = JSON.parse(eventForm.field_3);
              const field_4_json: EventFormField = JSON.parse(eventForm.field_4);
              const field_5_json: EventFormField = JSON.parse(eventForm.field_5);
              const field_6_json: EventFormField = JSON.parse(eventForm.field_6);
              const field_7_json: EventFormField = JSON.parse(eventForm.field_7);

              const fields: Array<EventFormField> = [];
              if (field_1_json.title.length > 0 || field_1_json.name.length > 0)
                fields.push({ title: field_1_json.title, name: field_1_json.name, type: field_1_json.type, unique: field_1_json.unique });
              if (field_2_json.title.length > 0 || field_2_json.name.length > 0)
                fields.push({ title: field_2_json.title, name: field_2_json.name, type: field_2_json.type, unique: field_2_json.unique });
              if (field_3_json.title.length > 0 || field_3_json.name.length > 0)
                fields.push({ title: field_3_json.title, name: field_3_json.name, type: field_3_json.type, unique: field_3_json.unique });
              if (field_4_json.title.length > 0 || field_4_json.name.length > 0)
                fields.push({ title: field_4_json.title, name: field_4_json.name, type: field_4_json.type, unique: field_4_json.unique });
              if (field_5_json.title.length > 0 || field_5_json.name.length > 0)
                fields.push({ title: field_5_json.title, name: field_5_json.name, type: field_5_json.type, unique: field_5_json.unique });
              if (field_6_json.title.length > 0 || field_6_json.name.length > 0)
                fields.push({ title: field_6_json.title, name: field_6_json.name, type: field_6_json.type, unique: field_6_json.unique });
              if (field_7_json.title.length > 0 || field_7_json.name.length > 0)
                fields.push({ title: field_7_json.title, name: field_7_json.name, type: field_7_json.type, unique: field_7_json.unique });
              if (fields.length === 0)
                fields.push({ title: "", name: "", type: "", unique: "" });

              this.eventForm = eventForm;
              this.setState({
                event_id: eventForm.event_id,
                fields: fields
              });
            } else {
              this.setState({ isLoading: false, data_loaded: false });
            }
          }
        } catch (e) {
          // console.log("error: ", e);
          this.setState({ isLoading: false, data_loaded: false });
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
      }
    });
  }

  onAddBtnClick() {
    if (!this.didMount) return;
    if (this.state.fields.length >= 7) return;
    const fields: Array<EventFormField> = this.state.fields;
    fields.push({ title: "", name: "", type: "", unique: "" });
    this.setState({ fields: fields });
    this.forceUpdate();
  }

  onValueChange(e: any, index: number, type: 'title' | 'name' | 'type' | 'unique') {
    // // console.log('onValueChange: ', index, type);
    if (!this.didMount) return;
    const fields: Array<EventFormField> = this.state.fields;
    fields[index][type] = e.target.value;
    
    this.setState({ fields: fields });
    this.forceUpdate();
  }

  onRemoveFieldClick(i: number) {
    if (!this.didMount) return;
    const fields: Array<EventFormField> = this.state.fields;
    fields.splice(i, 1);
    this.setState({ fields: fields });
    this.forceUpdate();
  }

  onOkBtnClick() {
    if (!this.didMount) return;
    if (!this.props.user_id) return;
    if (!this.state.event_id) return;
    if (!this.eventForm) return;
    const _fields: Array<EventFormField> = [];
    for (let a = 0; a < this.state.fields.length; a++) {
      _fields.push({
        title: this.state.fields[a].title,
        name: this.state.fields[a].name,
        type: this.state.fields[a].type,
        unique: this.state.fields[a].unique
      });
    }
    let fields = _fields;

    let isError = false;
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].title.length === 0 || fields[i].name.length === 0 || fields[i].type.length === 0 || fields[i].unique.length === 0) {
        isError = true;
        break;
      }
    }
    if (isError) {
      this.setState({ error: 'Please fill required field(s)', is_saving: false });
    }
    else {
      this.setState({ error: '', is_saving: false }); // '{\"title\":\"' + fields[0].title + '\",\"name\":\"' + fields[0].name + '\",\"type\":\"' + fields[0].type + '\"}'

      if (fields.length === 0) fields = this.addEmptyFields(fields, 7);
      else if (fields.length === 1) fields = this.addEmptyFields(fields, 6);
      else if (fields.length === 2) fields = this.addEmptyFields(fields, 5);
      else if (fields.length === 3) fields = this.addEmptyFields(fields, 4);
      else if (fields.length === 4) fields = this.addEmptyFields(fields, 3);
      else if (fields.length === 5) fields = this.addEmptyFields(fields, 2);
      else if (fields.length === 6) fields = this.addEmptyFields(fields, 1);
      // console.log('saving: prev: eventForm: ', this.eventForm);
      const eventForm: EventForm = {
        event_id: this.state.event_id,
        event_name: this.eventForm.event_name ? this.eventForm.event_name : "",
        created_date: this.eventForm.created_date ? this.eventForm.created_date : 0,
        event_image_title: this.eventForm.event_image_title ? this.eventForm.event_image_title : "",
        field_1: `{\"title\":\"${fields.length >= 1 ? fields[0].title : ""}\",\"name\":\"${fields.length >= 1 ? fields[0].name : ""}\",\"type\":\"${fields.length >= 1 ? fields[0].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[0].unique : ""}\"}`,
        field_2: `{\"title\":\"${fields.length >= 2 ? fields[1].title : ""}\",\"name\":\"${fields.length >= 2 ? fields[1].name : ""}\",\"type\":\"${fields.length >= 2 ? fields[1].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[1].unique : ""}\"}`,
        field_3: `{\"title\":\"${fields.length >= 3 ? fields[2].title : ""}\",\"name\":\"${fields.length >= 3 ? fields[2].name : ""}\",\"type\":\"${fields.length >= 3 ? fields[2].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[2].unique : ""}\"}`,
        field_4: `{\"title\":\"${fields.length >= 4 ? fields[3].title : ""}\",\"name\":\"${fields.length >= 4 ? fields[3].name : ""}\",\"type\":\"${fields.length >= 4 ? fields[3].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[3].unique : ""}\"}`,
        field_5: `{\"title\":\"${fields.length >= 5 ? fields[4].title : ""}\",\"name\":\"${fields.length >= 5 ? fields[4].name : ""}\",\"type\":\"${fields.length >= 5 ? fields[4].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[4].unique : ""}\"}`,
        field_6: `{\"title\":\"${fields.length >= 6 ? fields[5].title : ""}\",\"name\":\"${fields.length >= 6 ? fields[5].name : ""}\",\"type\":\"${fields.length >= 6 ? fields[5].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[5].unique : ""}\"}`,
        field_7: `{\"title\":\"${fields.length >= 7 ? fields[6].title : ""}\",\"name\":\"${fields.length >= 7 ? fields[6].name : ""}\",\"type\":\"${fields.length >= 7 ? fields[6].type : ""}\",\"unique\":\"${fields.length >= 1 ? fields[6].unique : ""}\"}`
      }
      // console.log('saving: -new: eventForm: ', eventForm);

      this.setState({ is_saving: true });
      this.databaseManager.updateForm(this.props.user_id, this.state.event_id, fields, {
        onResult: (result: any) => {
          if (!this.didMount) return;
          this.setState({ is_saving: false });
          if (result.success) {
            this.setState({ data_loaded: true });
            this.setState({ error: 'Form successful saved.' });
            this.eventForm = eventForm;
            this.props.onFormUpdated(eventForm);
            // wait for 1 sec
            setTimeout(() => {
              this.setState({ error: '' });
              this.forceUpdate();
            }, 1000);
          }
          else {
            this.setState({ is_saving: false, error: 'An error occured while saving form' });
          }
        },
        onError: (_error: any) => {
          if (!this.didMount) return;
          this.setState({ is_saving: false, error: 'An error occured while saving form' });
        }
      });
    }
  }

  addEmptyFields(fields: Array<EventFormField>, count: number): Array<EventFormField> {
    for (let i = 0; i < count; i++) {
      fields.push({ title: "", name: "", type: "", unique: "" });
    }
    return fields;
  }

  FormField(
    index: number,
    item_title: string,
    title: string,
    name: string,
    type: string,
    unique: string,
    onTitleChange: (event: React.ChangeEvent<any>, index: number) => void,
    onNameChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void,
    onTypeChange: (event: React.ChangeEvent<HTMLSelectElement>, index: number) => void,
    onUniqueChange: (event: React.ChangeEvent<HTMLSelectElement>, index: number) => void,
    onCancelClick: (event: any, index: number) => void
  ) {
    return (
      <div className="form-field" key={index + ''}>
        <button
          className="form-field-cancel-btn"
          onClick={(e) => onCancelClick(e, index)} >
          Remove
        </button>
        <span className="form-field-title">{item_title}</span>
        <div className="form-field-inputs">
          <input
            type="text"
            name="field-title"
            value={title}
            placeholder="Enter field title"
            onChange={(e) => onTitleChange(e, index)} />
          <input
            type="text"
            name="field-hint"
            value={name}
            placeholder="Enter field hint"
            onChange={(e) => onNameChange(e, index)} />
          <select
            name="field-type"
            value={type}
            onChange={(e) => onTypeChange(e, index)}>
            <option value="">Select field type -- </option>
            <option value="text">Type: Text</option>
            <option value="number">Type: Number</option>
            <option value="email">Type: Email</option>
            <option value="gender">Type: Gender</option>
            <option value="date">Type: Date</option>
            <option value="time">Type: Time</option>
            <option value="date-and-time">Type: Date &amp; Time</option>
          </select>
          <select
            name="field-unique"
            value={unique}
            onChange={(e) => onUniqueChange(e, index)}>
            <option value="">Unique field ? -- </option>
            <option value="yes">Field is Unique</option>
            <option value="no">Field is Not unique</option>
          </select>
          {/* <div className="field-selector-cont">
              <span>Select field type</span>
            </div> */}
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {(this.state.is_saving || this.state.error) &&
          <div className="form-output">
            {this.state.is_saving && <i className="fas fa-spinner fa-spin loading-icon"></i>}
            {this.state.error && <span
              className="error-text selector-none"
              onClick={(e) => {
                e.preventDefault();
                if (!this.didMount) return;
                this.setState({ error: '', is_saving: false });
              }}>
              {this.state.error}</span>}
          </div>}
        <div className="form-details"
          style={{
            display: this.props.current_page === currentPage.FORMS + "/" + this.props.event_id ? 'flex' : 'none'
          }}>
          <div className="form-details-list">
            {this.state.fields.map((field: EventFormField, index: number) => {
              return (
                this.FormField(
                  index,
                  "Field " + (index + 1),
                  field.title,
                  field.name,
                  field.type,
                  field.unique,
                  (e, i) => { this.onValueChange(e, i, 'title') },
                  (e, i) => { this.onValueChange(e, i, 'name') },
                  (e, i) => { this.onValueChange(e, i, 'type') },
                  (e, i) => { this.onValueChange(e, i, 'unique') },
                  (e, i) => { this.onRemoveFieldClick(i) }
                ));
            })}
            <div key={uuidV4()} className="form-details-btns-cont">
              <button
                className="form-details-ok-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  this.onOkBtnClick();
                }}
              >
                Save
              </button>
              <button
                className="form-details-add-btn"
                style={{ display: this.state.fields.length === 7 ? 'none' : 'flex' }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  this.onAddBtnClick();
                }}
              >
                Add Field
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}  