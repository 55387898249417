import './index.css';

import $ from "jquery";
import Artworks from './artworks';
import Dashboard from './dashboard';
import Events from './events';
import Forms from './forms';
import Header from './nav';
import React from 'react';
import { SideNav } from './nav';

// import Cookies from 'universal-cookie';







export const currentPage = {
  LANDING: '/',
  DASHBOARD: '/dashboard',
  EVENTS: '/events',
  FORMS: '/forms',
  ARTWORKS: '/artworks',
  APPLICANTS: '/applicants',
  REPORTS: '/reports',
  ACCOUNT: '/account',
  HELP: '/help',
  REGISTER: '/register',
  REGISTRATION: '/registration',
}

export default class Main extends React.Component<{
  history: any,
}, {
  username: string | null,
  user_id: string | null,
  current_page: string,
  nav_modal_active: boolean,
  header_title: string,
}> {

  private path: string = document.location.pathname;
  private didMount: boolean = false;
  // private cookies: Cookies = new Cookies();

  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
      user_id: null,
      current_page: currentPage.DASHBOARD,
      nav_modal_active: false,
      header_title: "Dashboard",
    }

    this.switchPages();
  }

  componentDidMount() {
    this.didMount = true;
    // const username = this.cookies.get('username');
    // const user_id = this.cookies.get('user_id');
    const first_name = window.localStorage.getItem('first_name');
    const user_id = window.localStorage.getItem('user_id');
    this.setState({
      username: first_name ? first_name : "Login",
      user_id: user_id,
    });
    var theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
    const theme_btn: any = document.getElementById("theme-btn");

    if (theme_btn) {
      theme_btn.checked = theme && theme === 'dark' ? true : false;
      theme_btn.addEventListener('change', (e: EventTarget | any) => {
        if (e && e.target) {
          theme = e.target.checked ? 'dark' : 'light';
          localStorage.setItem('theme', theme);
          document.documentElement.className = theme;
        }
      });
    }

    if (theme) document.documentElement.className = theme;
    window.onpopstate = (_event: any) => {
      this.switchPages();
    };
    $(document).on("click", "a", (_event: any) => {
      this.switchPages();
    });
    this.switchPages();
  }

  switchPages() {
    if (!this.didMount) return;
    // // console.log("location: " + document.location);
    // // console.log("path: " + document.location.pathname);
    this.path = document.location.pathname;
    const paths = window.localStorage.getItem('paths');
    if (paths) {
      const paths_arr: Array<string> = JSON.parse(paths);
      if (paths_arr.length > 20) {
        paths_arr.shift();
      }
      if (paths_arr[paths_arr.length - 1] !== this.props.history.location.pathname) {
        paths_arr.push(window.location.pathname);
        window.localStorage.setItem('paths', JSON.stringify(paths_arr));
      }
    }
    else {
      const paths_arr: Array<string> = [];
      paths_arr.push(window.location.pathname);
      window.localStorage.setItem('paths', JSON.stringify(paths_arr));
    }

    if (this.path.startsWith(currentPage.DASHBOARD)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Dashboard";
      this.setState({ header_title: "Dashboard", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.EVENTS)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Events";
      this.setState({ header_title: "Events", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.FORMS)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Forms";
      this.setState({ header_title: "Forms", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.ARTWORKS)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Artworks";
      this.setState({ header_title: "Artworks", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.APPLICANTS)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Applicants";
      this.setState({ header_title: "Applicants", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.REPORTS)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Reports";
      this.setState({ header_title: "Reports", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.ACCOUNT)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Account";
      this.setState({ header_title: "Account", nav_modal_active: false });
    } else if (this.path.startsWith(currentPage.HELP)) {
      this.setState({ current_page: this.path });
      document.title = "LR  - Help";
      this.setState({ header_title: "Help", nav_modal_active: false });
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  render() {
    return (
      <div className="main">
        <div className="main-nav-modal"
          style={{ display: this.state.nav_modal_active ? 'block' : 'none' }}
          onClick={() => {
            if (!this.didMount) return;
            this.setState({ nav_modal_active: false });
          }}
        />
        <SideNav
          history={this.props.history}
          current_page={this.state.current_page} />
        <div className={this.state.nav_modal_active ? "main-wrapper-wm" : "main-wrapper"}>
          <Header
            user_name={this.state.username}
            onNavMenuCluck={() => {
              if (!this.didMount) return;
              this.setState({ nav_modal_active: !this.state.nav_modal_active });
            }}
            nav_modal_active={this.state.nav_modal_active}
            header_title={this.state.header_title}
          />
          <Dashboard
            user_id={this.state.user_id}
            current_page={this.state.current_page}
            nav_modal_active={this.state.nav_modal_active} />
          <Events
            user_id={this.state.user_id}
            current_page={this.state.current_page}
            history={this.props.history}
            nav_modal_active={this.state.nav_modal_active} />
          <Forms
            user_id={this.state.user_id}
            current_page={this.state.current_page}
            nav_modal_active={this.state.nav_modal_active} />
            <Artworks
            user_id={this.state.user_id}
            current_page={this.state.current_page}
            nav_modal_active={this.state.nav_modal_active} />
        </div>
      </div>
    );
  }
}

