import './index.css';

import ArtworkDetails from './artwork-details';
import ArtworkListItem from './artwork-item';
import DatabaseManager from '../../../service';
import { EventArtwork } from '../../models';
import Loading from '../../loading';
import { LoadingType } from '../../loading';
import React from 'react';
import { Virtuoso } from "react-virtuoso";
import { currentPage } from '../';
import { v4 as uuidV4 } from 'uuid';

export type ArtworkObject = {
  key: string;
  eventArtwork: EventArtwork;
  selected: boolean;
  refreshId: number;
}

export default class Artworks extends React.Component<{
  user_id: string | null;
  current_page: string;
  nav_modal_active: boolean;
}, {
  artworks: Array<ArtworkObject>;
  selected_event_id: string | null,
  error: string,
  canUpdate: boolean,
  isLoading: boolean,
  data_loaded: boolean,
}> {

  private databaseManager: DatabaseManager;
  private didMount: boolean = false;
  private previous_current_page: string;
  private virtuoso: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      artworks: [],
      selected_event_id: null,
      error: "",
      canUpdate: false,
      isLoading: true,
      data_loaded: false,
    }
    this.databaseManager = new DatabaseManager();
    this.previous_current_page = this.props.current_page;
    this.updateArtworksLit = this.updateArtworksLit.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    if (!this.props.current_page.startsWith(currentPage.ARTWORKS)) return;
    if (this.props.user_id) {
      this.loadData();
    }
    this.selectArtwork();
  }

  componentDidUpdate() {
    // // console.log("Artworks componentDidUpdate");
    if (!this.didMount) return;
    if (!this.props.current_page.startsWith(currentPage.ARTWORKS)) return;
    if (!this.state.data_loaded) {
      if (this.props.user_id) {
        this.loadData();
      }
    }
    if (this.props.current_page !== this.previous_current_page) {
      this.previous_current_page = this.props.current_page;
      this.selectArtwork();
    }
  }

  selectArtwork() {
    if (!this.didMount) return;
    if (this.props.current_page.startsWith(currentPage.ARTWORKS + "/")
      && this.props.current_page.length >= 12) {
      const event_id = this.props.current_page.substring(10, this.props.current_page.length);
      if (event_id) {
        this.setState({
          selected_event_id: event_id,
        });
        // let selected_positon = -1;
        const artworks: Array<ArtworkObject> = this.state.artworks;
        for (let i = 0; i < artworks.length; i++) {
          artworks[i].selected = artworks[i].eventArtwork.event_id === event_id;
        }
        this.setState({
          artworks: artworks,
        });
      }
    } else {
      if (this.state.artworks.length > 0) {
        const artworks: Array<ArtworkObject> = this.state.artworks;
        for (let i = 0; i < artworks.length; i++) {
          artworks[i].selected = false;
        }
        this.setState({
          artworks: artworks,
        });
      }
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    if (!this.props.user_id) return;
    if (this.state.data_loaded) return;
    // // console.log("artworks: loadData: called");
    this.databaseManager.getAllArtworks(this.props.user_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
        const artworks: Array<ArtworkObject> = [];
        if (result) {
          if (result.success && result.success.data && result.success.data.length > 0) {
            // // console.log("data: ", result.success.data);
            for (let i = 0; i < result.success.data.length; i++) {
              const data = result.success.data[i];
              const eventArtwork: EventArtwork = {
                event_id: data.id ? data.id : "",
                event_name: data.name ? data.name : "",
                event_image_title: data.image_title ? data.image_title : "",
                event_artwork_title: data.artwork_title ? data.artwork_title : "",
              }
              artworks.push({
                key: eventArtwork.event_id + "-" + uuidV4(),
                eventArtwork: eventArtwork,
                selected: this.state.selected_event_id === eventArtwork.event_id,
                refreshId: 0,
              });
            }
          }
          this.setState({ artworks: artworks });
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
      }
    });
  }

  updateArtworksLit(eventArtwork: EventArtwork) {
    if (!this.didMount) return;
    // console.log("artworks: updateArtworksLit:                      " + eventArtwork);
    // console.log("artworks: updateArtworksLit: event_id:            " + eventArtwork.event_id);
    // console.log("artworks: updateArtworksLit: event_name:          " + eventArtwork.event_name);
    // console.log("artworks: updateArtworksLit: event_image_title:   " + eventArtwork.event_image_title);
    // console.log("artworks: updateArtworksLit: event_artwork_title: " + eventArtwork.event_artwork_title);
    const artworks: Array<ArtworkObject> = this.state.artworks;
    for (let i = 0; i < artworks.length; i++) {
      if (artworks[i].eventArtwork.event_id === eventArtwork.event_id) {
        // artworks[i].eventArtwork = eventArtwork;
        artworks[i].eventArtwork.event_id = eventArtwork.event_id;
        artworks[i].eventArtwork.event_name = eventArtwork.event_name;
        artworks[i].eventArtwork.event_image_title = eventArtwork.event_image_title;
        artworks[i].eventArtwork.event_artwork_title = eventArtwork.event_artwork_title;
        artworks[i].refreshId = artworks[i].refreshId + 1;
        break;
      }
    }
    this.setState({ artworks: artworks });
  }

  render() {
    return (
      <div className={this.props.nav_modal_active ? "artworks-main cont-wm" : "artworks-main"}
        style={{
          display: this.props.current_page.startsWith(currentPage.ARTWORKS) ? 'flex' : 'none'
        }}>
        <div className="artworks-main-first-lay">
          <div className="artworks-main-list">
            <span className="artworks-main-list-title">All Artworks</span>
            <Loading
              type={LoadingType.ARTWORKS}
              show={this.state.isLoading} />
            <Virtuoso
              className="artworks-list"
              ref={this.virtuoso}
              style={{ width: "100%", height: "100%" }}
              totalCount={this.state.artworks.length}
              itemContent={(index: number) => (
                <ArtworkListItem
                  artwork={this.state.artworks[index]}
                  selected={this.state.artworks[index].selected}
                  key={this.state.artworks[index].key}
                />
              )}
            />
            {!this.state.isLoading && this.state.artworks.length === 0 &&
              <div className="artworks-main-empty-cont">
                <div className="artworks-main-empty-top" />
                <img className="artworks-main-empty-img" src={require("../../../assets/images/no-events.png")} alt="" />
                <span className='artworks-main-empty-txt'>No artworks yet. Start to add events first</span>
              </div>
            }
          </div>
          <div className="artworks-main-edit"
            style={{
              display: this.props.current_page === currentPage.ARTWORKS + "/" + this.state.selected_event_id ? 'flex' : 'none',
            }}
          >
            <div
              // to={currentPage.ARTWORKS}
              className="artwork-details-top-cont"
              onClick={(e: any) => {
                e.preventDefault();
                window.history.back();
              }}>
              <i className="fa fa-arrow-left artwork-details-back-btn-icon"
                style={{
                  display: this.props.current_page === currentPage.ARTWORKS + "/" + this.state.selected_event_id ? 'flex' : 'none',
                }}></i>
              <span className="artworks-main-edit-title">Edit Artwork</span>
            </div>
            <span className="artworks-main-edit-empty-txt"
              style={{
                display: this.props.current_page === currentPage.ARTWORKS + "/" + this.state.selected_event_id ? 'none' : 'flex',
              }}>Select Event To Edit Its Artwork</span>
            <ArtworkDetails
              user_id={this.props.user_id}
              event_id={this.state.selected_event_id}
              current_page={this.props.current_page}
              onArtworkUpdated={(eventArtwork: EventArtwork) => this.updateArtworksLit(eventArtwork)}
            />
          </div>
        </div>
      </div>
    );
  }
}


