import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import AuthContext from './service/context';
// import Cookies from 'universal-cookie';
import Landing from './components/landing';
import Login from './components/login';
import Main from './components/main';
import React from 'react';
import Registration from './components/registration';
import SignUp from './components/signup';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <Routes />
    </AuthContext.Provider>
  );
}

export default App;

const Routes = () => {
  const Auth = React.useContext(AuthContext);
  return (
    <BrowserRouter>
      <div className="root-modal" id="root-modal" />
      <Switch>
        <Route exact path='/' render={({ history }) => <Landing history={history} />} />
        <Route path='/login' render={({ history }) => <Login history={history} />} />
        <Route path='/signup' render={({ history }) => <SignUp history={history} />} />
        <Route path={['/registration', '/registration/:id', '/register',]} render={({ history }) => <Registration history={history} />} />
        <ProtectedRoute
          path={[
            '/dashboard',
            '/events', '/events/:id',
            '/forms', '/forms/:id',
            '/artworks', '/artworks/:id',
            '/applicants', '/applicants/:id', '/applicants/:id/attended',
            '/reports', '/reports/:id',
            '/account',
            '/help',
          ]}
          auth={Auth.isAuthenticated}
          component={Main} />

      </Switch>
    </BrowserRouter>
  );
}

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // // // console.log('authorised: ' + auth);
        if (!auth) {
          // const cookies = new Cookies();
          // const user_id = cookies.get('user_id');
          const user_id = localStorage.getItem('user_id');
          auth = user_id ? true : false;
        }
        return (
          auth ? <Component {...props} /> : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }} />
          )
          // <AuthContext.Consumer>
          //   {(context) => {
          //     return (
          //       context.isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
          //     )
          //   }}
          // </AuthContext.Consumer>
        )
      }}
    />
  )
}
