import './index.css';

import DatabaseManager from '../../../service';
import Loading from '../../loading';
import { LoadingType } from '../../loading';
import Map from '../../map';
import React from 'react';
import { currentPage } from '../';

export default class Dashboard extends React.Component<{
  user_id: string | null;
  current_page: string;
  nav_modal_active: boolean;
}, {
  total_events: number,
  total_applicants: number,
  total_attended: number,
  isLoading: boolean,
  data_loaded: boolean,
}> {

  private databaseManager: DatabaseManager;
  private didMount: boolean = false;

  constructor(props: any) {
    super(props);

    this.state = {
      total_events: 0,
      total_applicants: 0,
      total_attended: 0,
      isLoading: true,
      data_loaded: false,
    }

    this.databaseManager = new DatabaseManager();
  }

  componentDidMount() {
    this.didMount = true;
    // // console.log("dash: mounted: user_id: ", this.props.user_id);
    if (!this.props.current_page.startsWith(currentPage.DASHBOARD)) return;
    if (this.props.user_id) {
      this.loadData();
    }
  }

  componentDidUpdate() {
    // // console.log("dash: updated: user_id: ", this.props.user_id);
    if (!this.didMount) return;
    if (!this.props.current_page.startsWith(currentPage.DASHBOARD)) return;
    if (!this.state.data_loaded) {
      if (this.props.user_id) {
        this.loadData();
      }
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    if (!this.props.user_id) return;
    // // console.log("dash: loadData: called");
    this.databaseManager.getDashboardData(this.props.user_id,
      {
        onResult: (result: any) => {
          if (!this.didMount) return;
          this.setState({ isLoading: false, data_loaded: true });
          if (result) {
            if (result.success && result.success.data && result.success.data.length > 0) {
              // // console.log("data: ", result.success.data);
              const data = result.success.data[0];
              if (data) {
                this.setState({
                  total_events: data.events_count ? data.events_count : 0,
                  total_applicants: data.applicants_count ? data.applicants_count : 0,
                  total_attended: data.attended_count ? data.attended_count : 0,
                });
              }
            }
          }
        },
        onError: (_error: any) => {
          if (!this.didMount) return;
          this.setState({ isLoading: false, data_loaded: true });
        }
      });
  }

  render() {
    return (
      <div className={this.props.nav_modal_active ? "dashboard-main cont-wm" : "dashboard-main"} 
      style={{ 
        display: this.props.current_page.startsWith(currentPage.DASHBOARD) ? 'flex' : 'none'
         }}>
        <div className="dash-main-first-lay">
          <div className="dash-main-events-count count-lay">
            <span className="dash-main-events-count count-text">{this.state.total_events}</span>
            <span className="dash-main-events-info count-info">{this.state.total_events === 1 ? 'Event' : 'Events'}</span>
          </div>
          <div className="dash-main-applicants count-lay">
            <span className="dash-main-applicants-count count-text">{this.state.total_applicants}</span>
            <span className="dash-main-applicants-info count-info">{this.state.total_applicants === 1 ? 'Applicant' : 'Applicants'}</span>
          </div>
          <div className="dash-main-attendees count-lay">
            <span className="dash-main-attendees-count count-text">{this.state.total_attended}</span>
            <span className="dash-main-attendees-info count-info">Attended</span>
          </div>
        </div>
        <div className="dash-main-second-lay">
          <div className="dash-main-latest-lay second-lay">
            <span className="latest-title">Latest</span>
            <div className="dash-main-latest-list-cont">
              <Loading
                type={LoadingType.APPLICANTS}
                show={true} />
            </div>
          </div>
          <div className="dash-main-map-lay second-lay">
            <Map
              is_dashboard={true}
            // locations={[]}
            // is_location_data_loaded={!this.state.dashboard_loading}
            // location={undefined}
            // last_location_updated={0}
            />
          </div>
        </div>
      </div>
    );
  }
}
