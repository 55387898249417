// import Cookies from 'universal-cookie';

import { EventFormField } from '../components/models';
import { OnResult } from './listeners';
import axios from 'axios';

// const host = "https://cors-anywhere.herokuapp.com/https://iot-cooler.imperialinnovations.co.tz";
const host = "https://events.imc.co.tz/api/v1/";
export const media_url = "https://events.imc.co.tz/uploads/media/images/";
// const host = "https://imc.co.tz/events/api/v1/";
// export const media_url = "https://imc.co.tz/events/uploads/media/images/";

const api_key = "axiopu45yru54piegh048yruht3wp";

export default class DatabaseManager {
    // private cookies: Cookies = new Cookies();
    private cancelToken: any = axios.CancelToken;
    private source: any = this.cancelToken.source();

    public loginUser(email: string, password: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            email: email,
            password: password,
            action: 'login'
        }

        try {
            const chunk = (new Date()).valueOf();

            const url = host + 'account?t=' + chunk;
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // 'cache-control': 'no-cache',
                    // 'pragma': 'no-cache'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // // console.log("daily_device_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public registerUser(first_name: string, last_name: string, email: string, password: string, callback: OnResult<any>): void {
            
            const request = {
                key: api_key,
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
                action: 'register'
            }

            // encrypt request with JWT token
            // const token = this.cookies.get('token');
            // const request = {
            //     key: api_key,
            //     first_name: first_name,
            //     last_name: last_name,
            //     email: email,
            //     password: password,
            //     action: 'register',
            //     token: token
            // }
    
            try {
                const url = host + 'account';
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: request,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        // // console.log("register: server: response: ", result);
                        callback.onResult(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        console.error("> axios error: ", err);
                        callback.onError(err);
                    })
                    .catch((thrown) => {
                        if (axios.isCancel(thrown)) {
                            // console.log('Request canceled', thrown);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                callback.onError(e);
            }
    }

    public getDashboardData(userId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            action: "dashboard_data"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // console.log("dashboard_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getAllEvents(userId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            action: "all_events"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // // console.log("all_events: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getSingleEvent(userId: string, eventId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            event_id: eventId,
            action: "single_event"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // console.log("single_event: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getAllForms(userId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            action: "all_forms"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // // console.log("all_forms: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getSingleForm(userId: string, eventId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            event_id: eventId,
            action: "single_form"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // console.log("single_form: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getAllArtworks(userId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            action: "all_artworks"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getSingleArtwork(userId: string, eventId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            user_id: userId,
            event_id: eventId,
            action: "single_artwork"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getRegistrationForm(eventId: string, callback: OnResult<any>): void {

        const request = {
            key: api_key,
            event_id: eventId,
            action: "registration_form"
        }

        try {
            const url = host + 'select';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // console.log("registration_form: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public createEvent(userId: string, image_file: File | null, name: string, venue: string, description: string, about: string, startDate: number, endDate: number, location: { lat: number, lng: number }, callback: OnResult<any>): void {
        const location_string = "{\"lat\":" + location.lat + ",\"lng\":" + location.lng + "}"
        // replace all ' to \" from name, venue and description
        if (name !== "") {
            name = name.replace(/'/g, "\\'");
            name = name.replace(/"/g, "\\'");
        }
        if (venue !== "") {
            venue = venue.replace(/"/g, "\\'");
            venue = venue.replace(/"/g, "\\'");
        }
        if (description !== "") {
            description = description.replace(/'/g, "\\'");
            description = description.replace(/"/g, "\\'");
        }
        if (about !== "") {
            about = about.replace(/'/g, "\\'");
            about = about.replace(/"/g, "\\'");
        }

        const formData = new FormData();
        if (image_file) {
            // const fileExtension = image.name.split('.').pop();
            formData.append('image_file', image_file);
        }
        formData.append('name', name);
        formData.append('venue', venue);
        formData.append('description', description);
        formData.append('about', about);
        formData.append('start_date', startDate + "");
        formData.append('end_date', endDate + "");
        formData.append('location', location_string);
        formData.append('user_id', userId);
        formData.append('key', api_key);
        formData.append('action', 'create_event');

        try {
            const url = host + 'update';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((result: any) => {
                    // console.log("single_event: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public updateEvent(userId: string, event_id: string,
        image_file: File | null, previous_image_title: string,
        name: string, venue: string, description: string, about: string,
        startDate: number, endDate: number, location: { lat: number, lng: number },
        callback: OnResult<any>): void {
        const location_string = "{\"lat\":" + location.lat + ",\"lng\":" + location.lng + "}"
        if (name !== "") {
            name = name.replace(/'/g, "\\'");
            name = name.replace(/"/g, "\\'");
        }
        if (venue !== "") {
            venue = venue.replace(/"/g, "\\'");
            venue = venue.replace(/"/g, "\\'");
        }
        if (description !== "") {
            description = description.replace(/'/g, "\\'");
            description = description.replace(/"/g, "\\'");
        }
        if (about !== "") {
            about = about.replace(/'/g, "\\'");
            about = about.replace(/"/g, "\\'");
        }

        const formData = new FormData();
        if (image_file) {
            // const fileExtension = image.name.split('.').pop();
            formData.append('image_file', image_file);
        }
        formData.append('pre_image_title', previous_image_title);
        formData.append('name', name);
        formData.append('venue', venue);
        formData.append('description', description);
        formData.append('about', about);
        formData.append('start_date', startDate + "");
        formData.append('end_date', endDate + "");
        formData.append('location', location_string);
        formData.append('user_id', userId);
        formData.append('event_id', event_id);
        formData.append('key', api_key);
        formData.append('action', 'update_event');

        try {
            const url = host + 'update';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((result: any) => {
                    // console.log("single_event: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public updateForm(userId: string, event_id: string, fields: Array<EventFormField>, callback: OnResult<any>): void {
        // // console.log("updateForm: fields: ", fields);
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('action', 'update_form');
        formData.append('user_id', userId);
        formData.append('event_id', event_id);
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (field.title.length > 0 && field.name.length > 0 && field.type.length > 0 && field.unique.length > 0) {
                var title = field.title.replace(/'/g, "\\'");
                title = title.replace(/"/g, "\\'");
                var name = field.name.replace(/'/g, "\\'");
                name = name.replace(/"/g, "\\'");
                var type = field.type.replace(/'/g, "\\'");
                type = type.replace(/"/g, "\\'");
                var unique = field.unique.replace(/'/g, "\\'");
                unique = unique.replace(/"/g, "\\'");
                formData.append('field_' + (i + 1), '{\"title\":\"' + title + '\",\"name\":\"' + name + '\",\"type\":\"' + type + '\",\"unique\":\"' + unique + '\"}');
            }
            else
                formData.append('field_' + (i + 1), '');
        }

        try {
            const url = host + 'update';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((result: any) => {
                    // console.log("single_event: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public updateArtwork(userId: string, event_id: string, image_file: File | null, previous_artwork_title: string, callback: OnResult<any>): void {


        const formData = new FormData();
        if (image_file) {
            // const fileExtension = image.name.split('.').pop();
            formData.append('image_file', image_file);
        }
        formData.append('pre_image_title', previous_artwork_title);
        formData.append('user_id', userId);
        formData.append('event_id', event_id);
        formData.append('key', api_key);
        formData.append('action', 'update_artwork');

        try {
            const url = host + 'update';
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((result: any) => {
                    // console.log("updateArtwork: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public cancelRequests() {
        this.source.cancel('Operation canceled by the user.');
        this.source = this.cancelToken.source();
    }

    public logoutUser(callback: OnResult<any>): void {
        try {
            // this.cookies.remove('user_id');
            // this.cookies.remove('user_id', { path: '/', domain: 'localhost' });
            // this.cookies.remove('user_id', { path: '/', domain: '.imc.co.tz' });
            // this.cookies.remove('username');
            // this.cookies.remove('username', { path: '/', domain: 'localhost' });
            // this.cookies.remove('username', { path: '/', domain: '.imc.co.tz' });
            localStorage.removeItem('user_id');
            localStorage.removeItem('first_name');
            localStorage.removeItem('last_name');

        } catch (e: any) {
            callback.onError(e);
        }

        setTimeout(() => {
            callback.onResult(true);
        }, 1000);
    }
}