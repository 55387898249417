import './index.css';

import DatabaseManager, { media_url } from '../../../service';
import { EventForm, EventFormField } from '../../models';

import FormDetails from './form-details';
import FormListItem from './form-item';
import Loading from '../../loading';
import { LoadingType } from '../../loading';
import React from 'react';
import { Virtuoso } from "react-virtuoso";
import { currentPage } from '../';
import { v4 as uuidV4 } from 'uuid';

export type FormObject = {
  key: string;
  eventForm: EventForm;
  selected: boolean;
  refreshId: number;
}

export default class Forms extends React.Component<{
  user_id: string | null;
  current_page: string;
  nav_modal_active: boolean;
}, {
  forms: Array<FormObject>;
  selected_event_id: string | null,
  error: string,
  canUpdate: boolean,
  isLoading: boolean,
  data_loaded: boolean,
}> {

  private databaseManager: DatabaseManager;
  private didMount: boolean = false;
  private previous_current_page: string;
  private virtuoso: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      forms: [],
      selected_event_id: null,
      error: "",
      canUpdate: false,
      isLoading: true,
      data_loaded: false,
    }
    this.databaseManager = new DatabaseManager();
    this.previous_current_page = this.props.current_page;
    this.updateFormsLit = this.updateFormsLit.bind(this);
  }

  componentDidMount() {
    this.didMount = true;
    if (!this.props.current_page.startsWith(currentPage.FORMS)) return;
    if (this.props.user_id) {
      this.loadData();
    }
    this.selectForm();
  }

  componentDidUpdate() {
    // // console.log("Forms componentDidUpdate");
    if (!this.didMount) return;
    if (!this.props.current_page.startsWith(currentPage.FORMS)) return;
    if (!this.state.data_loaded) {
      if (this.props.user_id) {
        this.loadData();
      }
    }
    if (this.props.current_page !== this.previous_current_page) {
      this.previous_current_page = this.props.current_page;
      this.selectForm();
    }
  }

  selectForm() {
    if (!this.didMount) return;
    if (this.props.current_page.startsWith(currentPage.FORMS + "/")
      && this.props.current_page.length >= 10) {
      const event_id = this.props.current_page.substring(7, this.props.current_page.length);
      if (event_id) {
        this.setState({
          selected_event_id: event_id,
        });
        // let selected_positon = -1;
        const forms: Array<FormObject> = this.state.forms;
        for (let i = 0; i < forms.length; i++) {
          forms[i].selected = forms[i].eventForm.event_id === event_id;
        }
        this.setState({
          forms: forms,
        });
      }
    } else {
      if (this.state.forms.length > 0) {
        const forms: Array<FormObject> = this.state.forms;
        for (let i = 0; i < forms.length; i++) {
          forms[i].selected = false;
        }
        this.setState({
          forms: forms,
        });
      }
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    if (!this.props.user_id) return;
    if (this.state.data_loaded) return;
    // // console.log("forms: loadData: called");
    this.databaseManager.getAllForms(this.props.user_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
        const forms: Array<FormObject> = [];
        if (result) {
          if (result.success && result.success.data && result.success.data.length > 0) {
            // // console.log("data: ", result.success.data);
            for (let i = 0; i < result.success.data.length; i++) {
              const data = result.success.data[i];
              const eventForm: EventForm = {
                event_id: data.event_id ? data.event_id : "",
                event_name: data.event_name ? data.event_name : "",
                created_date: data.created_date ? data.created_date : 0,
                event_image_title: data.event_image_title ? data.event_image_title : "",
                field_1: data.field_1 && data.field_1.length > 0 ? data.field_1 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}",
                field_2: data.field_2 && data.field_2.length > 0 ? data.field_2 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}",
                field_3: data.field_3 && data.field_3.length > 0 ? data.field_3 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}",
                field_4: data.field_4 && data.field_4.length > 0 ? data.field_4 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}",
                field_5: data.field_5 && data.field_5.length > 0 ? data.field_5 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}",
                field_6: data.field_6 && data.field_6.length > 0 ? data.field_6 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}",
                field_7: data.field_7 && data.field_7.length > 0 ? data.field_7 : "{\"title\":\"\",\"name\":\"\",\"type\":\"text\"}"
              }
              forms.push({
                key: eventForm.event_id + "-" + uuidV4(),
                eventForm: eventForm,
                selected: this.state.selected_event_id === eventForm.event_id,
                refreshId: 0,
              });
            }
          }
          this.setState({ forms: forms });
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
      }
    });
  }

  updateFormsLit(eventForm: EventForm) {
    if (!this.didMount) return;
    const forms: Array<FormObject> = this.state.forms;
    for (let i = 0; i < forms.length; i++) {
      if (forms[i].eventForm.event_id === eventForm.event_id) {
        forms[i].eventForm = eventForm;
        forms[i].refreshId = forms[i].refreshId + 1;
        break;
      }
    }
    this.setState({ forms: forms });
  }

  render() {
    return (
      <div className={this.props.nav_modal_active ? "forms-main cont-wm" : "forms-main"}
        style={{
          display: this.props.current_page.startsWith(currentPage.FORMS) ? 'flex' : 'none'
        }}>
        <div className="forms-main-first-lay">
          <div className="forms-main-list">
            <span className="forms-main-list-title">All Forms</span>
            <Loading
              type={LoadingType.FORMS}
              show={this.state.isLoading} />
            <Virtuoso
              className="forms-list"
              ref={this.virtuoso}
              style={{ width: "100%", height: "100%" }}
              totalCount={this.state.forms.length}
              itemContent={(index: number) => (
                <FormListItem
                  form={this.state.forms[index]}
                  selected={this.state.forms[index].selected}
                  key={this.state.forms[index].key}
                />
              )}
            />
            {!this.state.isLoading && this.state.forms.length === 0 &&
              <div className="forms-main-empty-cont">
                <div className="forms-main-empty-top" />
                <img className="forms-main-empty-img" src={require("../../../assets/images/no-events.png")} alt="" />
                <span className='forms-main-empty-txt'>No forms yet. Start to add events first</span>
              </div>
            }
          </div>
          <div className="forms-main-edit"
            style={{
              display: this.props.current_page === currentPage.FORMS + "/" + this.state.selected_event_id ? 'flex' : 'none',
            }}
          >
            <div
              // to={currentPage.FORMS}
              className="form-details-top-cont"
              onClick={(e: any) => {
                e.preventDefault();
                window.history.back();
              }}>
              <i className="fa fa-arrow-left form-details-back-btn-icon"
                style={{
                  display: this.props.current_page === currentPage.FORMS + "/" + this.state.selected_event_id ? 'flex' : 'none',
                }}></i>
              <span className="forms-main-edit-title">Edit Form</span>
            </div>
            <span className="forms-main-edit-empty-txt"
              style={{
                display: this.props.current_page === currentPage.FORMS + "/" + this.state.selected_event_id ? 'none' : 'flex',
              }}>Select Form To Edit</span>
            <FormDetails
              user_id={this.props.user_id}
              event_id={this.state.selected_event_id}
              current_page={this.props.current_page}
              onFormUpdated={(eventForm: EventForm) => this.updateFormsLit(eventForm)}
            />
          </div>
        </div>
      </div>
    );
  }
}


