import './index.css';

import React from 'react';

// import Cookies from 'universal-cookie';


export default class Landing extends React.PureComponent<{
  history: any,
}, {
  username: string | null,
}> {

  // private cookies: Cookies = new Cookies();

  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
    }

    this.onUserClick = this.onUserClick.bind(this);
    this.onDashboardBtnClick = this.onDashboardBtnClick.bind(this);
  }

  componentDidMount() {
    const paths = window.localStorage.getItem('paths');
    if (paths) {
      const paths_arr: Array<string> = JSON.parse(paths);
      if (paths_arr.length > 20) {
        paths_arr.shift();
      }
      if (paths_arr[paths_arr.length - 1] !== '/') {
        paths_arr.push('/');
        window.localStorage.setItem('paths', JSON.stringify(paths_arr));
      }
    }
    else {
      const paths_arr: Array<string> = [];
      paths_arr.push(window.location.pathname);
      window.localStorage.setItem('paths', JSON.stringify(paths_arr));
    }

    // const username = this.cookies.get('username');
    // const user_id = this.cookies.get('user_id');
    const first_name = localStorage.getItem('first_name');

    this.setState({ username: first_name ? first_name : "Login" })
    var theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
    const theme_btn: any = document.getElementById("theme-btn");
    document.title = "Laibon Registration";

    if (theme_btn) {
      theme_btn.checked = theme && theme === 'dark' ? true : false;
      theme_btn.addEventListener('change', (e: EventTarget | any) => {
        if (e && e.target) {
          theme = e.target.checked ? 'dark' : 'light';
          localStorage.setItem('theme', theme);
          document.documentElement.className = theme;
        }
      });
    }

    if (theme) document.documentElement.className = theme;
  }

  componentWillUnmount() {

  }

  onUserClick() {
    const user_id = window.localStorage.getItem('user_id');
    this.props.history.push(user_id ? '/dashboard' : '/login');
  }

  onDashboardBtnClick() {
    const user_id = window.localStorage.getItem('user_id');
    this.props.history.push(user_id ? '/dashboard' : '/login');
  }

  render() {
    return (
      <div className="landing-main">
        <LandingHeader
          user_name={this.state.username}
          onUserClick={() => this.onUserClick()}
        />
        <div className="first-lay">
          <div className="left-cont">
            <span className="first-title">
              Streamline Your Next Event With Laibon
            </span>
            <span className="open-devices-btn border-shadow"
              onClick={(e) => {
                e.preventDefault();
                this.onDashboardBtnClick();
              }}>
              Getting Started
            </span>
          </div>
          <div className="right-cont selector-none">
            {/* <img className="image selector-none" draggable="false" src={require("../../media/images/fridge.jpg")} alt='' /> */}
            <video src={require("../../assets/videos/landing-video-stub.mp4")} controls={false} autoPlay={true} playsInline={true} muted={true} loop={true}></video>
          </div>
        </div>
        <div className="second-lay">

        </div>
      </div>
    );
  }
}

class LandingHeader extends React.Component<{
  // onNavMenuClick(): void;
  // onThemeBtnClick(): void;
  // themeBtnChecked: boolean;
  onUserClick(): void;
  user_name: string | null;
}, {}> {

  render() {
    return (
      <div className="landing-header border-bottom">
        {/* <i className="fas fa-bars menu-btn"
          onClick={(e) => {
                    e.preventDefault();
                    this.props.onNavMenuClick();
                  }}
                  ></i> */}
        <span className="app-title">Laibon Registration</span>

        <div className="user-lay" title={this.props.user_name ? this.props.user_name : "User"}
          onClick={(e) => {
            e.preventDefault();
            this.props.onUserClick();
          }}>
          <p className="user-name">{this.props.user_name ? this.props.user_name : "User"}</p>
          <i className="fas fa-user user-icon"></i>
        </div>

        <div className="pallete-container">
          <label className="switch" title="Change theme">
            <input type="checkbox" className="checkbox" id="theme-btn" />
            <span className="toggle-thumb">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
              </svg>
            </span>
          </label>
        </div>
      </div>
    );
  }
}