import 'rsuite/dist/rsuite.min.css';
import './index.css';
import './event-new.css';

import DatabaseManager from '../../../service';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { DateRangePicker } from 'rsuite';
import EditEvent from './event-edit';
import EventDetails from './event-details';
import EventListItem from './event-item';
import Loading from '../../loading';
import { LoadingType } from '../../loading';
import Map from '../../map';
import Period from '../../period';
import React from 'react';
import { UserEvent } from '../../models';
import { Virtuoso } from "react-virtuoso";
import addDays from 'date-fns/addDays';
import { currentPage } from '../';
import { v4 as uuidV4 } from 'uuid';

const { before } = DateRangePicker;

export type EventObject = {
  key: string;
  userEvent: UserEvent;
  selected: boolean;
  refreshId: number;
}

export default class Events extends React.Component<{
  user_id: string | null;
  current_page: string;
  history: any;
  nav_modal_active: boolean;
}, {
  event_name: string,
  event_desc: string,
  event_about: string,
  event_venue: string,
  event_location: { lat: number, lng: number },
  isLocationModalOpen: boolean,
  isCalenderModalOpen: boolean,
  event_date: DateRange,
  event_image_src: string | null,
  event_image_name: string,
  event_image_file: File | null,
  error: string,
  canCreate: boolean,
  isLoading: boolean,
  data_loaded: boolean,
  events: Array<EventObject>,
  selected_event_id: string | null,
  is_creating: boolean,
  show_new_event_form: boolean,
  editing_event_id: string | null,
  event_details_refreshId: number,
}> {

  private databaseManager: DatabaseManager;
  private didMount: boolean = false;
  private previous_current_page: string;
  private virtuoso: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      event_name: "",
      event_desc: "",
      event_about: "",
      event_venue: "",
      event_location: { lat: 0, lng: 0 },
      isLocationModalOpen: false,
      isCalenderModalOpen: false,
      event_date: [Period.getTodayStartTime(), Period.getTodayEndTime()],
      event_image_src: null,
      event_image_name: "",
      event_image_file: null,
      // error: "Something went wrong!",
      error: "",
      canCreate: false,
      isLoading: true,
      data_loaded: false,
      events: [],
      selected_event_id: null,
      is_creating: false,
      show_new_event_form: false,
      editing_event_id: null,
      event_details_refreshId: 0,
    }

    this.databaseManager = new DatabaseManager();
    this.previous_current_page = this.props.current_page;
  }

  componentDidMount() {
    this.didMount = true;
    // // console.log("events: mounted: user_id: ", this.props.user_id);
    const root_modal = document.getElementById("root-modal");
    if (root_modal) {
      root_modal.addEventListener("click", (e: any) => {
        if (!this.didMount) return;
        if (e.target.id === "root-modal") {
          this.setState({
            isLocationModalOpen: false,
          });
          root_modal.style.display = "none";
        }
      });
    }
    if (!this.props.current_page.startsWith(currentPage.EVENTS)) return;
    if (this.props.user_id) {
      this.loadData();
    }
    this.selectEvent();
  }

  componentDidUpdate() {
    // // console.log("events: updated: props: ", this.props);
    if (!this.didMount) return;
    if (!this.props.current_page.startsWith(currentPage.EVENTS)) return;
    if (!this.state.data_loaded) {
      if (this.props.user_id) {
        this.loadData();
      }
    }
    if (this.props.current_page !== this.previous_current_page) {
      this.previous_current_page = this.props.current_page;
      this.selectEvent();
    }
  }

  selectEvent() {
    if (!this.didMount) return;
    // // console.log("select-event: currentPage: ", this.props.current_page);
    if (this.props.current_page.startsWith(currentPage.EVENTS + "/")
      && this.props.current_page.length >= 10) {
      // const index = this.props.current_page.indexOf("/events/");
      const event_id = this.props.current_page.substring(8, this.props.current_page.length);
      // // console.log("event_id: " + event_id);
      if (event_id) {
        this.setState({
          selected_event_id: event_id,
        });
        // let selected_positon = -1;
        const events: Array<EventObject> = this.state.events;
        for (let i = 0; i < events.length; i++) {
          events[i].selected = events[i].userEvent.id === event_id;
        }
        this.setState({
          events: events,
        });
        // if (selected_positon !== -1) {
        //   setTimeout(() => {
        //     if (this.virtuoso && this.virtuoso.current)
        //       this.virtuoso.current.scrollToIndex({
        //         index: selected_positon,
        //         align: "top",
        //         behavior: "auto",
        //       });
        //     // this.virtuoso.current.scrollToIndex(selected_positon);
        //   }, 1000);
        // }
      }
    } else {
      if (this.state.events.length > 0) {
        const events: Array<EventObject> = this.state.events;
        for (let i = 0; i < events.length; i++) {
          events[i].selected = false;
        }
        this.setState({
          events: events,
        });
      }
    }
  }

  updateEventsList(event: UserEvent) {
    if (!this.didMount) return;
    const events: Array<EventObject> = this.state.events;
    for (let i = 0; i < events.length; i++) {
      if (events[i].userEvent.id === event.id) {
        events[i].userEvent = event;
        events[i].refreshId = events[i].refreshId + 1;
        break;
      }
    }
    this.setState({
      events: events,
      event_details_refreshId: this.state.event_details_refreshId + 1
    });
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  loadData() {
    if (!this.props.user_id) return;
    if (this.state.data_loaded) return;
    // // console.log("events: loadData: called");
    this.databaseManager.getAllEvents(this.props.user_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
        const events: Array<EventObject> = [];
        if (result) {
          if (result.success && result.success.data && result.success.data.length > 0) {
            // // console.log("data: ", result.success.data);
            for (let i = 0; i < result.success.data.length; i++) {
              const data = result.success.data[i];
              const userEvent: UserEvent = {
                id: data.id ? data.id : "",
                name: data.name ? data.name : "Event",
                description: data.description ? data.description : "",
                about: data.about ? data.about : "",
                venue: data.venue ? data.venue : "",
                image_title: data.image_title ? data.image_title : null,
                artwork_title: data.artwork_title ? data.artwork_title : null,
                location_latlng: data.location_latlng ? data.location_latlng : null,
                applicants_count: data.applicants_count ? data.applicants_count : 0,
                attended_count: data.applicants_count ? data.applicants_count : 0,
                created_date: data.created_date ? data.created_date : 0,
                start_date: data.start_date ? data.start_date : 0,
                end_date: data.end_date ? data.end_date : 0,
              }
              events.push({
                key: userEvent.id + "-" + uuidV4(),
                userEvent: userEvent,
                selected: this.state.selected_event_id === userEvent.id,
                refreshId: 0,
              });
            }
          }
          this.setState({ events: events });
          // // console.log("loadData: onResult: state: ", this.state);
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false, data_loaded: true });
      }
    });
  }

  onValueChange = (field_type: 'name' | 'venue' | 'desc' | 'about', event: any) => {
    switch (field_type) {
      case 'name':
        this.setState({ event_name: event.target.value });
        break;
      case 'desc':
        this.setState({ event_desc: event.target.value });
        break;
      case 'about':
        this.setState({ event_about: event.target.value });
        break;
      case 'venue':
        this.setState({ event_venue: event.target.value });
        break;
    }
  };

  onSubmit = (event: any) => {
    if (!this.props.user_id) return;
    this.setState({ error: '' });
    event.preventDefault();

    const name: string = this.state.event_name;
    const venue: string = this.state.event_venue;
    const description: string = this.state.event_desc;
    const about: string = this.state.event_about;
    const image_file: File | null = this.state.event_image_file;
    const location_latlng = this.state.event_location;
    const start_date: number = (this.state.event_date[0]).valueOf();
    const end_date: number = (this.state.event_date[1]).valueOf();

    if (name.length === 0) {
      this.setState({ error: 'Please enter event name.' });
      return;
    }
    if (venue.length === 0) {
      this.setState({ error: 'Please enter event venue or location name.' });
      return;
    }
    if (description.length === 0) {
      this.setState({ error: 'Please enter event description.' });
      return;
    }
    this.setState({ is_creating: true });

    this.databaseManager.createEvent(this.props.user_id, image_file, name, venue, description, about, start_date, end_date, location_latlng, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ is_creating: false });
        if (result.success) {
          // if (result.success.data && result.success.data.length > 0) {
          //   const data = result.success.data[0];
          //   // console.log("createEvent: onResult: data: ", data);
          // }
          this.setState({
            event_name: '',
            event_desc: '',
            event_about: '',
            event_venue: '',
            event_image_src: null,
            event_image_name: '',
            event_location: { lat: 0, lng: 0 },
            event_date: [Period.getTodayStartTime(), Period.getTodayEndTime()],
            data_loaded: false,
          });
          setTimeout(() => {
            if (!this.didMount) return;
            // reload data
            this.loadData();
            if (result.success.data && result.success.data.length > 0) {
              const new_event_id: string = result.success.data[0].event_id;
              // console.log("createEvent: onResult: new_event_id: ", new_event_id);
              // this.props.history.push(currentPage.EVENTS + "/" + new_event_id);
              // go to url with window
              window.location.href = currentPage.EVENTS + "/" + new_event_id;
            }
          }, 1000);
        } else {
          this.setState({ error: 'Something went wrong while creating event.\nPlease try again.' });
          // console.log("createEvent: onResult: error: ", result.error);
        }
      },
      onError: (_error: any) => {
        if (!this.didMount) return;
        this.setState({ is_creating: false, error: 'Something went wrong while creating event.\nPlease try again.' });
      }
    });
  }

  private datePicker() {
    const lower_date = new Date();
    lower_date.setFullYear(2022, 1, 1);
    lower_date.setHours(0, 0, 0, 0);

    // const initialValue: DateRange | null | undefined =
    //  [Period.getTodayStartTime(), Period.getTodayEndTime()];

    return (
      <DateRangePicker
        format={"dd/MM/yyyy HH:mm:ss"}
        appearance="subtle"
        placeholder="Select event period"
        character="  ~  "
        // size="sm"
        defaultValue={this.state.event_date}
        cleanable={false}
        disabledDate={
          // combine && combine(before && before(lower_date), afterToday && afterToday())
          // combine && combine(before && before(lower_date))
          before && before(lower_date)
        }
        style={{
          backgroundColor: 'var(--color-background)',
          borderRadius: '8px',
        }}
        ranges={[
          // {
          //   label: 'Yesterday',
          //   value: [addDays(Period.getDayStartTime(), -1), addDays(Period.getDayEndTime(), -1)]
          // },
          {
            label: 'Today',
            value: [Period.getTodayStartTime(), Period.getTodayEndTime()]
          },
          {
            label: 'Tomorrow',
            value: [addDays(new Date(), 1), addDays(new Date(), 1)]
          },
          // {
          //   label: 'Last 7 days',
          //   value: [subDays(Period.getDayStartTime(), 6), Period.getDayEndTime()]
          // }
        ]}
        onOpen={() => {
          this.setState({ isCalenderModalOpen: true });
          // const root_modal = document.getElementById("root-modal");
          // if (root_modal) {
          //   root_modal.style.display = "flex";
          // }
        }}
        onChange={(date: DateRange | null, event: React.SyntheticEvent<Element, Event>) => {
          // console.log('date-range-picker: changed: ', date);
          // if (date)
          //   this.setState({ event_date: date });
          // this.onSideNavDeviceClick(this.state.selected_device_id, true)
        }}
        onOk={(date: DateRange, event: React.SyntheticEvent) => {
          event.preventDefault();
          // console.log('date-range-picker: ok: ', date);
          if (date)
            this.setState({ event_date: date });
          // this.onSideNavDeviceClick(this.state.selected_device_id, true)
        }}
        onClose={() => {
          this.setState({ isCalenderModalOpen: false });
          // const root_modal = document.getElementById("root-modal");
          // if (root_modal) {
          //   root_modal.style.display = "none";
          // }
        }}
      />
    );
  }

  render() {
    return (
      <div className={this.props.nav_modal_active ? "events-main cont-wm" : "events-main"}
        style={{
          display: this.props.current_page.startsWith(currentPage.EVENTS) ? 'flex' : 'none'
        }}>
        <div className="events-main-first-lay">
          <div className="events-main-list">
            <button className="events-main-add-btn"
              onClick={(e) => {
                e.preventDefault();
                if (!this.didMount) return;
                this.setState({ show_new_event_form: true });
              }}>Add Event</button>
            <span className="events-main-list-title">All Events</span>
            <Loading
              type={LoadingType.EVENTS}
              // show={true}
              show={this.state.isLoading}
            />
            <Virtuoso
              className="events-list"
              ref={this.virtuoso}
              style={{ width: "100%", height: "100%" }}
              totalCount={this.state.events.length}
              // components={{
              //   Footer: () => {
              //     return (
              //       <div className="log-cont">
              //         <span className={"log-time log-info-text"}>
              //           {"> " + new Date()}
              //         </span>
              //         <span
              //           className={"log-text log-info-text text-cursor-anim"}
              //           style={{
              //             animation: `animated-text 4s steps(${
              //               this.state.logs.length + 19
              //             }, end) 1s 1 normal both,
              //         animated-cursor 600ms steps(${
              //           this.state.logs.length + 19
              //         }, end) infinite`,
              //           }}
              //         >
              //           {` *** ${this.state.logs.length} entries ...`}
              //         </span>
              //       </div>
              //     );
              //   },
              // }}
              itemContent={(index: number) => (
                <EventListItem
                  event={this.state.events[index]}
                  selected={this.state.events[index].selected}
                  refreshId={this.state.events[index].refreshId}
                  key={this.state.events[index].key}
                />
              )}
            />
            {!this.state.isLoading && this.state.events.length === 0 &&
              <div className="events-main-empty-cont">
                <div className="events-main-empty-top" />
                <img className="events-main-empty-img" src={require("../../../assets/images/no-events.png")} alt="" />
                <span className='events-main-empty-txt'>No events yet. Start to add events</span>
              </div>
            }
          </div>
          <div className={
            this.state.show_new_event_form ? "events-main-new events-main-new-wm" : "events-main-new"
          }
            style={{
              display: this.props.current_page === currentPage.EVENTS ? 'flex' : 'none',
              // position: this.state.show_new_event_form ? 'absolute' : 'relative',
            }}>
            <button className={
              this.state.show_new_event_form ?
                "events-main-create-cancel-btn events-main-create-cancel-btn-wm" :
                "events-main-create-cancel-btn"}
              onClick={(e) => {
                e.preventDefault();
                if (!this.didMount) return;
                this.setState({ show_new_event_form: false });
              }}>Cancel</button>
            <span className={
              this.state.show_new_event_form ?
                "events-main-create-title events-main-create-title-wm" :
                "events-main-create-title"
            }>Create New Event</span>
            {
              (this.state.is_creating || this.state.error) &&
              <div className="event-form-output">
                {this.state.is_creating && <i className="fas fa-spinner fa-spin loading-icon"></i>}
                {this.state.error && <span
                  className="error-text selector-none"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!this.didMount) return;
                    this.setState({ error: '', is_creating: false });
                  }}>
                  {this.state.error}</span>}
              </div>
            }
            <form className='event-form-cont' onSubmit={this.onSubmit}>
              <input
                className='name-input input'
                name='event_name'
                value={this.state.event_name}
                onChange={(e) => this.onValueChange('name', e)}
                type='text'
                placeholder='Enter Event Name' />
              <input
                // rows={5}
                // cols={50}
                maxLength={100}
                className='desc-input input'
                name='event_desc'
                type='text'
                value={this.state.event_desc}
                onChange={(e) => this.onValueChange('desc', e)}
                placeholder='Short Description' />
              <input
                // rows={200}
                // cols={50}
                maxLength={1000}
                className='about-input input'
                name='event_about'
                type='text'
                value={this.state.event_about}
                onChange={(e) => this.onValueChange('about', e)}
                placeholder='About (optional)' />
              <input
                className='venue-input input'
                name='event_venue'
                value={this.state.event_venue}
                onChange={(e) => this.onValueChange('venue', e)}
                type='text'
                placeholder='Venue Or Location Name' />
              <button
                className='event-location-btn'
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  this.setState({ isLocationModalOpen: true });
                  // const root_modal = document.getElementById("root-modal");
                  // if (root_modal) {
                  //   root_modal.style.display = "flex";
                  // }
                }}>
                Select Event Location (optional)
              </button>
              <div className="event-location-map-modal"
                style={{
                  display: this.state.isLocationModalOpen || this.state.isCalenderModalOpen ? 'flex' : 'none'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  this.setState({ isLocationModalOpen: false, isCalenderModalOpen: false });
                }} />
              <div className='event-location-map-cont'
                style={{
                  display: this.state.isLocationModalOpen ? 'flex' : 'none'
                }}>
                <Map
                  is_dashboard={false}
                // locations={[]}
                // is_location_data_loaded={!this.state.dashboard_loading}
                // location={undefined}
                // last_location_updated={0}
                />
              </div>
              <span className="event-date-title">Select Event Date</span>
              <div
                className="event-date-cont">
                {this.datePicker()}
              </div>
              <button
                className='event-image-btn'
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  // const root_modal = document.getElementById("root-modal");
                  // if (root_modal) {
                  //   root_modal.style.display = "flex";
                  // }
                  const imageInputFile = document.getElementById("event-image-input-file");
                  if (imageInputFile) {
                    imageInputFile.click();
                  }
                }}>
                Select Event's Profile Image (optional)
              </button>
              <input className="event-image-input"
                hidden={true}
                multiple={false}
                type="file"
                id="event-image-input-file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  e.preventDefault();
                  if (!this.didMount) return;
                  const files: FileList | null = e.target.files;
                  if (files && files.length > 0) {
                    const file: File = files[0];
                    if (file) {
                      const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
                      const actual_filesize = parseFloat(filesize);
                      if (actual_filesize <= 2) {
                        const filePath = URL.createObjectURL(file);
                        this.setState({
                          event_image_src: filePath,
                          event_image_name: file.name,
                          event_image_file: file,
                        });
                      } else {
                        this.setState({ error: 'Image size should be less than 2 MB' });
                      }
                    }
                  }
                }}
              />
              {this.state.event_image_src &&
                <div className="event-image-cont">
                  <i className="fas fa-times-circle event-image-icon"
                    title={"remove image"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!this.didMount) return;
                      this.setState({
                        event_image_src: null,
                        event_image_name: "",
                      });
                    }} />
                  <div className="event-image-top" />
                  <img
                    className="event-image"
                    src={this.state.event_image_src}
                    alt={"event profile image"}
                  />
                </div>
              }
              <span className="event-date-title">Once you create event you will be able to upload your artwork.</span>
              <button
                className='create-event-btn'
                disabled={this.state.canCreate}
                type='submit'
              >
                Create
              </button>
            </form>
          </div>
          <EventDetails
            user_id={this.props.user_id}
            event_id={this.state.selected_event_id}
            current_page={this.props.current_page}
            onEditClick={(event_id: string) => {
              if (!this.didMount) return;
              this.setState({ editing_event_id: event_id });
            }}
            refreshId={this.state.event_details_refreshId}
          />
          {this.state.editing_event_id &&
            <EditEvent
              user_id={this.props.user_id}
              event_id={this.state.editing_event_id}
              onUpdateComplete={(event: UserEvent) => {
                if (!this.didMount) return;
                this.setState({ editing_event_id: null });
                this.updateEventsList(event);
              }}
              onCancelUpdate={() => {
                if (!this.didMount) return;
                this.setState({ editing_event_id: null });
              }} />}
        </div>
      </div>
    );
  }
}